export const AdminOnboardingOrgsActionTypes = {

  AdminOnboardingOrgsInviteRecordsRequest: "[AdminOnboardingOrgs] Invite Org Records Request",
  AdminOnboardingOrgsInviteRecordsSuccess: "[AdminOnboardingOrgs] Invite Org Records Success",
  AdminOnboardingOrgsInviteRecordsFailure: "[AdminOnboardingOrgs] Invite Org Records Failure"

};

export default AdminOnboardingOrgsActionTypes;

