export default {

  getRecIdsByOrgId: state => orgId => {
    if (state.recs === null) {
      return [];
    }

    return Object.keys(state.recs).filter(pid => {
      if (state.recs[pid].organization &&
          state.recs[pid].organization._id == orgId
        ) {
          return true;
        }

        return false;
    });
  },

  getRecsByOrgId: (state, getters) => (orgId, pageId, pageSize) => {
    if (pageId === undefined) { pageId = 1; }
    if (pageSize === undefined) { pageSize = 25; }
    let start = (pageId - 1) * pageSize;
    if (! state.recs) {
      return [];
    }

    return getters.getRecIdsByOrgId(orgId).slice(start, start + pageSize).map(pid => state.recs[pid]);
  },


};
