

export default function genActionTypes (name) {

  const ActionTypes = {
    LoadRecordsPageRequest: "[" + name + "] Load RecordsPage Request",
    LoadRecordsPageSuccess: "[" + name + "] Load RecordsPage Success",
    LoadRecordsPageFailure: "[" + name + "] Load RecordsPage Failure",

    ClearPageRecords: "[" + name + "] Clear Page Records",
    ClearRecords: "[" + name + "] Clear Records",

    GetRecordRequest: "[" + name +"] Get Record Request",
    GetRecordSuccess: "[" + name + "] Get Record Success",
    GetRecordFailure: "[" + name + "] Get Record Failure",

    DelRecordRequest: "[" + name +"] Del Record Request",
    DelRecordSuccess: "[" + name + "] Del Record Success",
    DelRecordFailure: "[" + name + "] Del Record Failure",

    LoadTotalsRequest: "[" + name + "] Load Totals Request",
    LoadTotalsSuccess: "[" + name + "] Load Totals Success",
    LoadTotalsFailure: "[" + name + "] Load Totals Failure",

  }

  return ActionTypes;

}