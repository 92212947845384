<template>
  <tr>
    <td class="q-pt-xs text-no-wrap" valign="top">
      {{ post[dateField] | date  }}
    </td>
    <td v-if="showPosted">
      <q-chip v-if="post.posted" square dense >
        <q-avatar color="green" text-color="white" icon="check" />
        Posted
      </q-chip>
      <q-chip v-if="! post.posted" square dense >
        <q-avatar color="red" text-color="white" icon="close" />
        Not Posted
      </q-chip>

    </td>
    <td>
      <div class="text-body1 text-weight-medium">
        <q-icon v-if="showBookmark && !isBookmarked(post._id)" name="fas fa-bookmark" class="q-mr-sm" color="grey" @click="bookmarkPost(post._id)" />
        <q-icon v-if="showBookmark && isBookmarked(post._id)" name="fas fa-bookmark" class="q-mr-sm" color="primary" @click="unbookmarkPost(post._id)" />
        <router-link :to="{ name: viewRouteName, params: { recId: post._id }}" class="text-primary">{{post.headline || 'No Headline'}}</router-link>
      </div>
      <span v-if="post.organization" class="text-caption">{{post.organization.name}}</span>
    </td>
    <td class="text-right">
      <div class="text-no-wrap">
      {{post.location.city}}<span v-if="post.location.state_code">,
      <router-link :to="{ name: 'jobStateList', params: { stateCode: post.location.state_code, pageId: 1 } }" class="text-primary">{{post.location.state_code}}</router-link></span>
      </div>
      {{post.location.country_code}}
    </td>
  </tr>
</template>
<style scoped>
</style>
<script>
// import { AccountActionsActionTypes } from "../store/module-account-actions/action-types";
// import { AccountPostActionTypes } from '../store/module-account-post/action-types';

export default {
  name: 'JobListItem',
  props: {
    'post': {
      type: Object,
      default: null,
      required: true
    },
    'showPosted': {
      type: Boolean,
      default: false,
    },
    'showBookmark': {
      type: Boolean,
      default: true,
    },
    'dateField': {
      type: String,
      default: 'posted_on',
    },
    'viewRouteName': {
      type: String,
      default: 'jobView',
    }
  },
  data() {
    return {
    }
  },
  methods: {
    isBookmarked(postId) {
      return false;
    //   return this.$store.getters['accountActions/isBookmarked'](postId);
    },
    // async setPosted() {
    //     this.$store.dispatch(`accountPost/${AccountPostActionTypes.AccountPostFieldOnRequest}`, { key: 'posted', postId: this.post._id });
    // },
    // async setUnPosted() {
    //     this.$store.dispatch(`accountPost/${AccountPostActionTypes.AccountPostFieldOffRequest}`, { key: 'posted', postId: this.post._id });
    // },
    // bookmarkPost(postId) {
    //   this.bookmarkSaving = true;
    //   this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsBookmarkRecordRequest, { postId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
    // },
    // unbookmarkPost(postId) {
    //   this.bookmarkSaving = true;
    //   this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsDelBookmarkRecordRequest, { postId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
    // },
  }
}
</script>

