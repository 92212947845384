import auth from 'src/modules/auth';
import site from 'src/modules/site';
import job from 'src/modules/job';
import organization from 'src/modules/organization';
import account from 'src/modules/account';
import admin from 'src/modules/admin';
import pages from 'src/modules/pages';

export default ({ Vue, router, store }) => {
  // register auth module...

  [auth, site, job, organization, account, admin, pages].forEach(module => {
    if (module.store) {
      store.registerModule(module.name, module.store);
    }

    if (module.routes) {
      router.addRoutes(module.routes);
    }

    if (module.components) {
      module.components.forEach(component => {
        Vue.component(component.name, component);
      });
    }
  });
};
