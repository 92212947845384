// commit is dispatch
/* eslint-disable no-unused-vars */
// commit is dispatch
/* eslint-disable no-unused-vars */
import Vue from "vue";
// import { uid } from 'quasar'
import { AdminActionTypes } from "./action-types";

export default {
  [AdminActionTypes.AdminLoadUserRecordsRequest]: (state, payload) => {
    state.userLoading = true;
    state.error = null;
  },

  [AdminActionTypes.AdminLoadUserRecordsSuccess]: (state, payload) => {
    console.log('POST LOAD SUCCESS', payload);
    state.userLoading = false;
    state.userLoaded = true;

    state.error = null;
    let userData = {};
    payload.data.recs.forEach( rec => {
      userData[ rec._id ] = rec;
    });
    Vue.set(state, "users", userData);
    Vue.set(state, "userIds", payload.data.recs.map(r => r._id));
  },

  [AdminActionTypes.AdminLoadUserRecordsFailure]: (state, payload) => {
    state.userLoading = false;
    state.error = payload.error;
  },

  [AdminActionTypes.AdminLoadServerErrorRecordsRequest]: (state, payload) => {
    state.serverErrorLoading = true;
    state.error = null;
  },

  [AdminActionTypes.AdminLoadServerErrorRecordsSuccess]: (state, payload) => {
    console.log('ServerError LOAD SUCCESS', payload);
    state.serverErrorLoading = false;
    state.serverErrorLoaded = true;

    state.error = null;
    let serverErrorData = {};
    let serverErrorIds = [];
    payload.data.recs.forEach( rec => {
      serverErrorData[ rec._id ] = rec;
      serverErrorIds.push(rec._id);
    });
    Vue.set(state, "serverErrors", serverErrorData);
    Vue.set(state, "serverErrorIds", serverErrorIds);
  },

  [AdminActionTypes.AdminLoadServerErrorRecordsFailure]: (state, payload) => {
    state.serverErrorLoading = false;
    state.error = payload.error;
  },

  [AdminActionTypes.AdminLoadJSErrorRecordsRequest]: (state, payload) => {
    state.jsErrorLoading = true;
    state.error = null;
  },

  [AdminActionTypes.AdminLoadJSErrorRecordsSuccess]: (state, payload) => {
    console.log('JSError LOAD SUCCESS', payload);
    state.jsErrorLoading = false;
    state.jsErrorLoaded = true;

    state.error = null;
    let jsErrorData = {};
    let jsErrorIds = [];
    payload.data.recs.forEach( rec => {
      jsErrorData[ rec._id ] = rec;
      jsErrorIds.push(rec._id);
    });
    Vue.set(state, "jsErrors", jsErrorData);
    Vue.set(state, "jsErrorIds", jsErrorIds);
  },

  [AdminActionTypes.AdminLoadJSErrorRecordsFailure]: (state, payload) => {
    state.jsErrorLoading = false;
    state.error = payload.error;
  },

  [AdminActionTypes.AdminFieldSetSuccess]: (state, payload) => {
    let key =  payload.vuexCollection || payload.collection;
    let fields = key.split('.');

    let ob = fields.reduce( (a,b) => a[b], state);

    console.log("FIELD SET KEY", key, fields, ob, payload.rec._id);

    Vue.set(ob, payload.rec._id, payload.rec);
    // if (payload.setMeta) {
    //   Vue.set(ob[ payload.rec._id ], 'meta', payload.rec);
    // }
  },

  [AdminActionTypes.AdminFieldAddDaysSuccess]: (state, payload) => {
    let key =  payload.vuexCollection || payload.collection;
    let fields = key.split('.');

    let ob = fields.reduce( (a,b) => a[b], state);

    Vue.set(ob, payload.rec._id, payload.rec);
  },

  [AdminActionTypes.AdminFieldSubDaysSuccess]: (state, payload) => {
    let key =  payload.vuexCollection || payload.collection;
    let fields = key.split('.');

    let ob = fields.reduce( (a,b) => a[b], state);

    Vue.set(ob, payload.rec._id, payload.rec);
  },

  [AdminActionTypes.AdminFieldPullSuccess]: (state, payload) => {
    let key =  payload.vuexCollection || payload.collection;
    let fields = key.split('.');

    let ob = fields.reduce( (a,b) => a[b], state);

    Vue.set(ob, payload.rec._id, payload.rec)
  },

  [AdminActionTypes.AdminFieldPushSuccess]: (state, payload) => {
    let key =  payload.vuexCollection || payload.collection;
    let fields = key.split('.');

    let ob = fields.reduce( (a,b) => a[b], state);

    Vue.set(ob, payload.rec._id, payload.rec)
  },

  [AdminActionTypes.AdminFieldAddToSetSuccess]: (state, payload) => {
    Vue.set(state[payload.collection], payload.rec._id, payload.rec);
  },

  [AdminActionTypes.AdminGetUserRecordSuccess]: (state, payload) => {
    if (state.users === null) {
      Vue.set(state, 'users', { [payload._id]: payload });
      Vue.set(state, 'userIds', [ payload._id ]);
    } else {
      if (! state.users[payload._id]) {
        Vue.set(state, 'userIds', [ ...state.userIds, payload._id]);
      }

      Vue.set(state.users, payload._id, payload);
    }
  },

  [AdminActionTypes.AdminGetJSErrorRecordSuccess]: (state, payload) => {
    if (state.jsErrors === null) {
      Vue.set(state, 'jsErrors', { [payload._id]: payload });
      Vue.set(state, 'jsErrorIds', [ payload._id ]);
    } else {
      if (! state.jsErrors[payload._id]) {
        Vue.set(state, 'jsErrorIds', [ ...state.jsErrorIds, payload._id]);
      }

      Vue.set(state.jsErrors, payload._id, payload);
    }
  },

  [AdminActionTypes.AdminSetUserFilterQry]: (state, payload) => {
    Vue.set(state.userFilter, 'qry', payload );
  },

  [AdminActionTypes.AdminSetServerErrorFilterQry]: (state, payload) => {
    Vue.set(state.serverErrorFilter, 'qry', payload );
  },

  [AdminActionTypes.AdminSetJSErrorFilterQry]: (state, payload) => {
    Vue.set(state.jsErrorFilter, 'qry', payload );
  },

  [AdminActionTypes.AdminBecomeUserSuccess]: (state, payload) => {
  },

};
