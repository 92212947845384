import axios from 'axios';

const accessToken = localStorage.getItem('access_token') || null;
const refreshToken = localStorage.getItem('refresh_token') || null;

function parseJwt(token) {
  if (!token || token.indexOf('.') === -1) {
    return null;
  }

  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

let user = {
  _id: null,
  email: null,
  roles: [],
  first_name: 'Guest',
  last_name: '',
};

let loggedIn = false;

if (accessToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  // loggedIn = false;
  user = parseJwt(accessToken);
  if (user && user._id) {
    loggedIn = true;
  }
}

export default {
  // initial state
  user: user,
  token: accessToken,
  refreshToken: refreshToken,

  startUrl: '/',

  loggedIn: loggedIn,
  loading: false,
  error: null,
};
