import JobView from './pages/jobs/job-view.vue';

const routes = [

  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [
      {
        path: "jobs",
        component: () => import("./pages/jobs/job-base.vue"),
        alias: "posts",
        children: [
          { name: 'jobList', path: "list/:pageId", component: () => import("./pages/jobs/job-list.vue") },
          { name: 'jobView', path: "view/:recId", component: JobView },

          { name: 'jobSearch', path: "search/:pageId", component: () => import("./pages/jobs/job-search.vue") },

          { meta: { requiresAuth: true, requiredRoles: ['jobseeker'] }, name: 'jobApply', path: "apply/:jobId", component: () => import("./pages/jobs/job-apply.vue") },

          // { name: 'jobAdd', path: "add", component: () => import("./pages/jobs/job-add.vue"), meta: {
              // requiresAuth: true
            // } },

          { name: 'jobStateTotals', path: "state", component: () => import("./pages/jobs/job-state/job-state-totals.vue") },
          { name: 'jobStateList', path: "state/:stateCode/:pageId", component: () => import("./pages/jobs/job-state/job-state-list.vue"), meta: {requiresAuth: true} },
          { name: 'jobStateView', path: "state/:stateCode/view/:recId", component: JobView },

          { name: 'jobSkillTotals', path: "skill", component: () => import("./pages/jobs/job-skill/job-skill-totals.vue") },
          { name: 'jobSkillList', path: "skill/:skill/:pageId", component: () => import("./pages/jobs/job-skill/job-skill-list.vue"), meta: {requiresAuth: true} },
          { name: 'jobSkillView', path: "skill/:skill/view/:postId", component: JobView },

          { name: 'jobCountryTotals', path: "country", component: () => import("./pages/jobs/job-country/job-country-totals.vue") },
          { name: 'jobCountryList', path: "country/:countryCode/:pageId", component: () => import("./pages/jobs/job-country/job-country-list.vue"), meta: {requiresAuth: true} },
          { name: 'jobCountryView', path: "country/:countryCode/view/:postId", component: JobView },

          { name: 'jobDegreeTotals', path: "degree", component: () => import("./pages/jobs/job-degree/job-degree-totals.vue") },
          { name: 'jobDegreeList', path: "degree/:degree/:pageId", component: () => import("./pages/jobs/job-degree/job-degree-list.vue"), meta: {requiresAuth: true} },
          { name: 'jobDegreeView', path: "degree/:degree/view/:postId", component: JobView },

          { name: 'jobOrgTypeTotals', path: "orgtype", component: () => import("./pages/jobs/job-orgtype/job-orgtype-totals.vue") },
          { name: 'jobOrgTypeList', path: "orgtype/:orgType/:pageId", component: () => import("./pages/jobs/job-orgtype/job-orgtype-list.vue"), meta: {requiresAuth: true} },
          { name: 'jobOrgTypeView', path: "orgtype/:orgType/view/:postId", component: JobView },

          { name: 'jobContractTypeTotals', path: "contract_type", component: () => import("./pages/jobs/job-contract-type/job-contract-type-totals.vue") },
          { name: 'jobContractTypeList', path: "contract_type/:contractType/:pageId", component: () => import("./pages/jobs/job-contract-type/job-contract-type-list.vue"), meta: {requiresAuth: true} },
          { name: 'jobContractTypeView', path: "contract_type/:contractType/view/:postId", component: JobView },

        ],
      },
    ],
  },

];

export default routes;
