import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import actionTypes from "./action-types"

import genModule from "../../../store_base";

let module = genModule('AdminOrgs', '/api/admin/orgs', actionTypes );

export default {
  namespaced: true,
  getters: {
    ...module.getters,
    ...getters,
  },

  actions: {
    ...module.actions,
    ...actions,
  },

  mutations: {
    ...module.mutations,
    ...mutations,
  },

  state: {
    ...module.state,
    ...state,
  },

  actionTypes: module.actionTypes,

};
