
const routes = [

  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [

      { name: 'about', path: "about", component: () => import("./pages/help/about.vue") },
      { name: 'faq', path: "faq", component: () => import("./pages/help/faq.vue") },
      { name: 'terms', path: "terms", component: () => import("./pages/help/terms.vue") },
      { name: 'privacy', path: "privacy", component: () => import("./pages/help/privacy.vue") },
      { name: 'refund', path: "refund", component: () => import("./pages/help/refund.vue") },


    ],
  },

];

export default routes;
