// commit is dispatch
/* eslint-disable no-unused-vars */
import Vue from "vue";
// import { uid } from 'quasar'
import { AdminCrawlerActionTypes } from "./action-types";

export default {

  [AdminCrawlerActionTypes.AdminCrawlerJobRecordsImportSuccess]: (state, payload) => {
    state.loading = false;
    state.loaded = true;

    state.error = null;
    let jobData = {};

    payload.data.ids.forEach( id => {
      delete state.recs[id];
      // state.jobs[id].imported = true;
    });

    // Vue.set(state, "jobs", jobData);
    Vue.set(state, "recIds", state.recIds.filter(
      id => !payload.data.ids.includes(id)
    ) );
  },

};