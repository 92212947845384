// import Vue from "vue";
import { AdminOnboardingOrgsActionTypes } from "./action-types";

export default {

  [AdminOnboardingOrgsActionTypes.AdminOnboardingOrgsInviteRecordsSuccess]: (state, payload) => {

    payload.ids.forEach( id => {
      state.recs[id].imported = true;
    });

    if (payload.failed_ids) {
      payload.failed_ids.forEach( id => {
        state.recs[id].reviewed = true;
      });
    }

  },


};
