// commit is dispatch
/* eslint-disable no-unused-vars */
import Vue from "vue";
// import { uid } from 'quasar'
import { AdminOrgsActionTypes } from "./action-types";

export default {



};