
import genActionTypes from "./action-types";

import ApiService from "../../services/api.service";
import { notifyError } from '../../services/utils';
import { isEqual } from 'lodash'


export default function genActions ( name, apiPath ) {

  const actionTypes = genActionTypes(name);

  return {

    [actionTypes.LoadRecordsPageRequest]: async ({ commit, state, dispatch }, payload) => {

      let qry =  payload.qry;

      if (isEqual(payload.qry, state.qry)) {
        if (! payload.extra) {
          let p2 = { extra: true,  ... payload };
          p2.page_id += 1;
          dispatch(actionTypes.LoadRecordsPageRequest, p2 );
        }

        if (state.pages[payload.page_id] && ! payload.force) {
          return;
        }
      } else {
        await commit(actionTypes.ClearRecords);
      }

      commit(actionTypes.LoadRecordsPageRequest, payload);

      // Do housekeeping on rec cache
      let keep_pages = [payload.page_id, payload.page_id + 1];
      if (payload.page_id > 1) {
        for (var x=1; x<3; x++) {
          let page = payload.page_id - x;
          if (page > 0) {
            keep_pages.push(page);
          }
        }
      }

      // remove all recs and pages we aren't keeping
      Object.keys(state.pages).filter(x => {
        return ! keep_pages.includes(+x)
      }).forEach( page_id => {
        commit(actionTypes.ClearPageRecords, { page_id });
      });

      try {
        const apiData = await ApiService.post(apiPath, payload);
        commit(actionTypes.LoadRecordsPageSuccess, { qry, ... apiData.data});
        return true;
      } catch (error) {
        console.log("caught error in load " + name + " records", error.response);
        notifyError('Load ' + name + ' Records Request', error);
        throw error;
      }
    },

    [actionTypes.ClearPageRecords]: async ({ commit }, payload) => {
      commit(actionTypes.ClearPageRecords, payload);
    },

    [actionTypes.GetRecordSuccess]: async ({ commit }, payload) => {
      commit(actionTypes.GetRecordSuccess, payload);
    },

    [actionTypes.GetRecordRequest]: async ({ commit }, payload) => {
      commit(actionTypes.GetRecordRequest, payload);
      try {
        const apiData = await ApiService.get(apiPath + '/' + payload.recId);
        commit(actionTypes.GetRecordSuccess, apiData.data);
        return true;
      } catch (error) {
        console.log("caught error in get " + name + " record", error.response);
        notifyError('Get ' + name + ' Record Request', error);
        throw error;
      }
    },

    [actionTypes.DelRecordRequest]: async ({ commit }, payload) => {
      commit(actionTypes.DelRecordRequest, payload);
      try {
        const apiData = await ApiService.delete(apiPath + '/' + payload.recId);
        if (payload.onSuccess) {
          payload.onSuccess();
        }
        commit(actionTypes.DelRecordSuccess, apiData.data);
        return true;
      } catch (error) {
        console.log("caught error in get " + name + " record", error.response);
        notifyError('Get ' + name + ' Record Request', error);
        throw error;
      }
    },

    [actionTypes.LoadTotalsRequest]: async ({ commit }) => {

      // commit(PostActionTypes.PostsLoadTotalsRequest, payload);

      try {
        const apiData = await ApiService.get(apiPath + '/totals');
        commit(actionTypes.LoadTotalsSuccess, apiData.data);

        return true;
      } catch (error) {
        console.log("caught error in login", error.response);

        return false;
      }
    },



  };

};
