import { Notify } from 'quasar';

export const createNotify = ({ message, color, position, timeout, icon} ) => {
  Notify.create({
    position: position || 'top-right',
    color: color || 'red',
    icon: icon || 'warning',
    timeout: timeout || 3500,
    message: message || '[ No message ]',
    actions: [
      { icon: 'close', color: 'white' },
    ],
  });
};

export const notifyError = ( name, error ) => {
  if (error && error.response) {
    if (error.response.data && error.response.data.message) {
      createNotify({
          message: `${name}: [${error.response.status}] ${error.response.data.message}`,
          timeout: 7500,
      });
    } else {
        createNotify({
          message: `${name}: ${error.response.status} ${error.response.statusText}`,
          timeout: 7500,
        });
    }
  } else {
    console.log('error is obj', error);
    createNotify({
      message: `${name}: ${error || ''}`,
      timeout: 7500,
    });
  }
};

export const notifySuccess = ( message ) => {
  createNotify({
    message,
    color: 'green',
    icon: 'done',
  });
};

export default {
  createNotify,
  notifyError,
  notifySuccess,
};


