const routes = [
  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [
      {
        path: 'auth',
        component: () => import('./pages/box.vue'),
        children: [
          { path: 'login', component: () => import('./pages/login.vue') },
          { path: 'register', component: () => import('./pages/register.vue') },
          { path: 'forgot', component: () => import('./pages/forgot.vue') },
          { path: 'resetpass/:uid/:secs/:chk', component: () => import('./pages/reset.vue'), props: true },
          { path: 'confirm/:uid', props: true, component: () => import('./pages/confirm.vue') },
        ],
      },
    ],
  },
];

export default routes;
