import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import genModule from "../../store_base";

let module = genModule('organization', '/api/organizations');

export default {
  namespaced: true,
  getters: {
    ...module.getters,
    ...getters,
  },

  actions: {
    ...module.actions,
    ...actions,
  },

  mutations: {
    ...module.mutations,
    ...mutations,
  },

  state: {
    ...module.state,
    ...state,
  },

  actionTypes: module.actionTypes,

};
