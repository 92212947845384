// commit is dispatch

import { AdminActionTypes } from "./action-types";
import { AuthActionTypes } from "src/modules/auth/store/action-types";

// import router from "../../router";
import ApiService from "src/services/api.service";
// import api from '../../utils/api';
import { Notify } from 'quasar';
import { notifyError, notifySuccess } from 'src/services/utils';

export default {
  [AdminActionTypes.AdminLoadUserRecordsRequest]: async ({ commit }, payload) => {
    console.log("in load all users req action", payload);
    commit(AdminActionTypes.AdminLoadUserRecordsRequest, payload);
    console.log("in load all users req action", payload);

    try {
      const userData = await ApiService.get('/api/admin/users');
      console.log("in admin user load data action", userData);
      commit(AdminActionTypes.AdminLoadUserRecordsSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in load user records", error.response);
      throw error;
    }
  },

  [AdminActionTypes.AdminLoadServerErrorRecordsRequest]: async ({ commit }, payload) => {
    commit(AdminActionTypes.AdminLoadServerErrorRecordsRequest, payload);

    try {
      const apiData = await ApiService.get('/api/admin/server_errors');
      commit(AdminActionTypes.AdminLoadServerErrorRecordsSuccess, apiData.data);
      return true;
    } catch (error) {
      console.log("caught error in load ServerError records", error.response);
      notifyError('Load ServerError Requests', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminLoadJSErrorRecordsRequest]: async ({ commit }, payload) => {
    commit(AdminActionTypes.AdminLoadJSErrorRecordsRequest, payload);

    try {
      const apiData = await ApiService.get('/api/admin/js_errors');
      commit(AdminActionTypes.AdminLoadJSErrorRecordsSuccess, apiData.data);
      return true;
    } catch (error) {
      console.log("caught error in load jserror records", error.response);
      notifyError('Load JSError Requests', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminGetJSErrorRecordRequest]: async ({ commit }, payload) => {

    try {
      const apiData = await ApiService.get('/api/admin/js_errors/' + payload.jsErrorId);
      console.log('apiData', apiData);
      commit(AdminActionTypes.AdminGetJSErrorRecordSuccess, apiData.data.rec);
      return true;
    } catch (error) {
      console.log("caught error in get js error record", error.response);
      notifyError('Get JS Error Record Request', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminGetUserRecordRequest]: async ({ commit }, payload) => {

    try {
      const apiData = await ApiService.get('/api/admin/users/' + payload.userId);
      console.log('apiData', apiData);
      commit(AdminActionTypes.AdminGetUserRecordSuccess, apiData.data);
      return true;
    } catch (error) {
      console.log("caught error in get user record", error.response);
      notifyError('Get User Record Request', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldOnRequest]: async ({ commit }, payload) => {
    try {
      const apiRes  = await ApiService.post('/api/admin/' + payload.collection + '/field_on/' + payload.id, payload);
      commit(AdminActionTypes.AdminFieldOnSuccess, apiRes.data);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldOnRequest", error.response.data);
      notifyError('Get User Record Request', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldOffRequest]: async ({ commit }, payload) => {
    try {
      const apiRes  = await ApiService.post('/api/admin/' + payload.collection + '/field_off/' + payload.id, payload);
      commit(AdminActionTypes.AdminFieldOffSuccess, apiRes.data);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldOffRequest", error.response.data);
      Notify.create({
        position: 'top-right',
        color: 'red',
        icon: 'warning',
        timeout: 2500,
        message: error.response.data.message,
        actions: [
          { icon: 'close', color: 'white' },
        ],
      });
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldSetRequest]: async ({ commit }, payload) => {
    try {
      // const apiRes  = await ApiService.post('/api/admin/' + payload.collection + '/field_set/' + payload.id, payload);
      const apiRes  = await ApiService.post('/api/admin/field_set', payload);
      commit(AdminActionTypes.AdminFieldSetSuccess, {
        collection: payload.vuexCollection || payload.collection,
        id: payload.id,
        rec: apiRes.data.rec,
        setMeta: payload.setMeta});

      Notify.create({
        position: 'top-right',
        color: 'green',
        icon: 'warning',
        timeout: 2500,
        message: apiRes.data.message,
        actions: [
          { icon: 'close', color: 'white' },
        ],
      });
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldSetRequest", error.response.data);
      Notify.create({
        position: 'top-right',
        color: 'red',
        icon: 'warning',
        timeout: 2500,
        message: error.response.data.message,
        actions: [
          { icon: 'close', color: 'white' },
        ],
      });
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldAddDaysRequest]: async ({ commit }, payload) => {
    try {
      // const apiRes  = await ApiService.post('/api/admin/' + payload.collection + '/field_set/' + payload.id, payload);
      const apiRes  = await ApiService.post('/api/admin/field_add_days', payload);
      commit(AdminActionTypes.AdminFieldAddDaysSuccess, {
        collection: payload.vuexCollection || payload.collection,
        id: payload.id,
        rec: apiRes.data.rec});

      notifySuccess(apiRes.data.message);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldAddDaysRequest", error.response.data);
      notifyError('AddDaysRequestRequest', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldSubDaysRequest]: async ({ commit }, payload) => {
    try {
      // const apiRes  = await ApiService.post('/api/admin/' + payload.collection + '/field_set/' + payload.id, payload);
      const apiRes  = await ApiService.post('/api/admin/field_sub_days', payload);
      commit(AdminActionTypes.AdminFieldSubDaysSuccess, {
        collection: payload.vuexCollection || payload.collection,
        id: payload.id,
        rec: apiRes.data.rec});
      notifySuccess(apiRes.data.message);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldSubDaysRequest", error.response);
      notifyError('SubDaysRequestRequest', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldAddToSetRequest]: async ({ commit }, payload) => {
    try {
      const apiRes  = await ApiService.post('/api/admin/field_add_to_set', payload);
      commit(AdminActionTypes.AdminFieldAddToSetSuccess, {
        collection: payload.collection,
        id: payload.id,
        rec: apiRes.data.rec});
      notifySuccess(apiRes.data.message);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldAddToSetRequest", error.response.data);
      notifyError('FieldAddToSetRequest', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldPullRequest]: async ({ commit }, payload) => {
    try {
      // const apiRes  = await ApiService.post('/api/admin/' + payload.collection + '/field_pull/' + payload.id, payload);
      const apiRes  = await ApiService.post('/api/admin/field_pull', payload);
      commit(AdminActionTypes.AdminFieldPullSuccess, {
        collection: payload.vuexCollection || payload.collection,
        id: payload.id,
        rec: apiRes.data.rec});
      notifySuccess(apiRes.data.message);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldPullRequest", error.response.data);
      notifyError('FiledPullRequest', error);
      throw error;
    }
  },

  [AdminActionTypes.AdminFieldPushRequest]: async ({ commit }, payload) => {
    try {
      const apiRes  = await ApiService.post('/api/admin/field_push', payload);
      commit(AdminActionTypes.AdminFieldPushSuccess, {
        collection: payload.vuexCollection || payload.collection,
        id: payload.id,
        rec: apiRes.data.rec});
      notifySuccess(apiRes.data.message);
      return true;
    } catch (error) {
      console.log("caught error in AdminFieldPushRequest", error.response.data);
      notifyError('FiledPushRequest', error);
      throw error;
    }
  },


  [AdminActionTypes.AdminAddRole]: async ({ commit }, payload) => {
    commit(`auth/${AuthActionTypes.AuthAddRole}`, payload, {root:true});
  },

  [AdminActionTypes.AdminRemoveRole]: async ({ commit }, payload) => {
    commit(`auth/${AuthActionTypes.AuthRemoveRole}`, payload, {root:true});
  },


};
