// import JobView from '../job/pages/jobs/job-view.vue';

const routes = [

  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [
      {
        path: "account",
        // meta: { requiresAuth: true, },
        meta: { requiresAuth: true, requiredRoles: ['user'] },
        component: () => import( /* webpackChunkName: "group-account" */ "./pages/account/account-base.vue"),
        children: [
          { path: "home", name: 'accountHome', component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-home.vue") },

          {
            name: "accountChpass",
            path: "chpass",
            component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-chpass.vue") },

          // {
          //   name: "accountEdit",
          //   path: "edit",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-chpass.vue") },


          // {
          //   name: "accountJobseekerEdit",
          //   path: "jobseeker/edit",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobseeker.vue") },

          // {
          //   name: "accountJobsList",
          //   path: "jobs/list",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-list/account-jobs-list-base.vue"),
          //   children: [
          //     {
          //       name: "accountJobsListCurrent",
          //       path: "current/:pageId",
          //       component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-list/account-jobs-list-current.vue") },
          //     {
          //       name: "accountJobsListPending",
          //       path: "pending/:pageId",
          //       component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-list/account-jobs-list-pending.vue") },
          //     {
          //       name: "accountJobsListExpired",
          //       path: "expired/:pageId",
          //       component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-list/account-jobs-list-pending.vue") },
          //   ],
          // },

          // {
          //   name: "accountJobsManage",
          //   path: "jobs/manage/:postId",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-manage.vue") },

          // // {
          // //   name: "accountJobsAdd",
          // //   path: "jobs/add",
          // //   props: { step: 1 },
          // //   // component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-add/account-jobs-add-base.vue"),
          // //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-add/account-jobs-add-review.vue") },

          // // {
          // //   name: "accountJobsAddReview",
          // //   path: "jobs/review/:postId",
          // //   props: { step: 2 },
          // //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-add/account-jobs-add-review.vue") },

          // {
          //   name: "accountJobsPrepStart",
          //   path: "jobs/prep/start",
          //   props: { step: 1 },
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-prep/account-jobs-prep-start.vue") },

          // {
          //   name: "accountJobsPrepReview",
          //   path: "jobs/prep/review/:postId",
          //   props: { step: 2 },
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-prep/account-jobs-prep-review.vue") },

          // {
          //   name: "accountJobsPrepUpdateApply",
          //   path: "jobs/prep/:jobId/apply_info",
          //   props: { step: 2 },
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-prep/account-jobs-prep-update-apply.vue") },

          // {
          //   name: "accountJobsPrepUpdateDetails",
          //   path: "jobs/prep/:jobId/details",
          //   props: { step: 2 },
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-prep/account-jobs-prep-update-details.vue") },

          // {
          //   name: "accountJobsPrepUpdateJob",
          //   path: "jobs/prep/:jobId/job",
          //   props: { step: 2 },
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-prep/account-jobs-prep-update-job.vue") },

          // // {
          // //   name: "accountJobsAddPay",
          // //   path: "jobs/pay/:postId",
          // //   props: { step: 3 },
          // //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-jobs/account-jobs-add/account-jobs-add-review.vue") },

          // {
          //   name: "accountOrdersList",
          //   path: "orders/list/:pageId",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-orders/account-orders-list.vue") },
          // {
          //   name: "accountOrdersView",
          //   path: "orders/view/:orderId",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-orders/account-orders-view.vue") },

          // {
          //   name: "accountBookmarksList",
          //   path: "bookmarks/list/:pageId",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-bookmarks/account-bookmarks-list.vue") },

          // {
          //   name: "accountViewHistoryList",
          //   path: "viewhistory/list/:pageId",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-view-history/account-view-history-list.vue") },

          // {
          //   name: "accountAppHistoryList",
          //   path: "apphistory/list/:pageId",
          //   component: () => import(/* webpackChunkName: "group-account" */ "./pages/account/account-app-history/account-app-history-list.vue") },

        ],
      },
    ],
  },

];

export default routes;
