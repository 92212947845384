// commit is dispatch
/* eslint-disable no-unused-vars */
import Vue from "vue";
// import { uid } from 'quasar'
import { AdminOnboardingActionTypes } from "./action-types";

export default {

  [AdminOnboardingActionTypes.AdminOnboardingLoadOrgRecordsRequest]: (state, payload) => {
    state.orgLoading = true;
    state.error = null;
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadOrgRecordsSuccess]: (state, payload) => {
    state.orgLoading = false;
    state.orgLoaded = true;

    state.error = null;
    let orgData = {};
    payload.data.recs.forEach( rec => {
      orgData[ rec._id ] = rec;
    });
    Vue.set(state, "orgs", orgData);
    Vue.set(state, "orgIds", payload.data.recs.map(r => r._id));
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadOrgRecordsFailure]: (state, payload) => {
    state.orgLoading = false;
    state.error = payload.error;
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobseekerRecordsRequest]: (state, payload) => {
    state.orgLoading = true;
    state.error = null;
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobseekerRecordsSuccess]: (state, payload) => {
    state.orgLoading = false;
    state.orgLoaded = true;

    state.error = null;
    let orgData = {};
    payload.data.recs.forEach( rec => {
      orgData[ rec._id ] = rec;
    });
    Vue.set(state, "orgs", orgData);
    Vue.set(state, "orgIds", payload.data.recs.map(r => r._id));
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobseekerRecordsFailure]: (state, payload) => {
    state.orgLoading = false;
    state.error = payload.error;
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobRecordsRequest]: (state, payload) => {
    state.jobLoading = true;
    state.error = null;
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobRecordsSuccess]: (state, payload) => {
    state.jobLoading = false;
    state.jobLoaded = true;

    state.error = null;
    let jobData = {};
    payload.data.recs.forEach( rec => {
      jobData[ rec._id ] = rec;
    });
    Vue.set(state, "jobs", jobData);
    Vue.set(state, "jobIds", payload.data.recs.map(r => r._id));
  },

  [AdminOnboardingActionTypes.AdminOnboardingJobGetRecordRequest]: (state, payload) => {
    state.jobLoading = true;
  },

  [AdminOnboardingActionTypes.AdminOnboardingJobGetRecordSuccess]: (state, payload) => {
    state.jobLoading = false;

    if (state.jobs === null) {
      Vue.set(state, 'jobs', { [payload._id]: payload });
      Vue.set(state, 'jobIds', [ payload._id ]);
    } else {
      if (! state.jobs[payload._id]) {
        Vue.set(state, 'jobIds', [ ...state.jobIds, payload._id]);
      }

      Vue.set(state.jobs, payload._id, payload);
    }
  },

  [AdminOnboardingActionTypes.AdminOnboardingJobRecordsImportSuccess]: (state, payload) => {
    state.jobLoading = false;
    state.jobLoaded = true;

    state.error = null;
    let jobData = {};

    payload.data.ids.forEach( id => {
      delete state.jobs[id];
      // state.jobs[id].imported = true;
    });

    // Vue.set(state, "jobs", jobData);
    Vue.set(state, "jobIds", Object.keys(state.jobs) );
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobRecordsFailure]: (state, payload) => {
    state.jobLoading = false;
    state.error = payload.error;
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetOrgFilterQry]: (state, payload) => {
    Vue.set(state.orgFilter, 'qry', payload );
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetJobFilterQry]: (state, payload) => {
    Vue.set(state.jobFilter, 'qry', payload.qry );
    Vue.set(state.jobFilter, 'field', payload.field );
  },

  [AdminOnboardingActionTypes.AdminOnboardingAddFilterSkipImported]: (state, payload) => {
    Vue.set(state.orgFilter, 'nonImported', payload );
  },

  [AdminOnboardingActionTypes.AdminOnboardingAddFilterSkipReviewed]: (state, payload) => {
    Vue.set(state.orgFilter, 'nonReviewed', payload );
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetOrgRecordsReviewedSuccess]: (state, payload) => {
    state.orgLoading = false;
    state.orgLoaded = true;

    state.error = null;
    let orgData = {};
    payload.data.ids.forEach( id => {
      state.orgs[id].reviewed = true;
    });
  },


};