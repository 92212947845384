export const AdminUsersActionTypes = {

  AdminBecomeUserRequest: "[AdminUsers] Become User Request",
  AdminBecomeUserSuccess: "[AdminUsers] Become User Success",
  AdminBecomeUserFailure: "[AdminUsers] Become User Failure",

  AdminUsersSetPassRequest: "[AdminUsers] Set Pass Request",
  AdminUsersSetPassFailure: "[AdminUsers] Set Pass Failure",
  AdminUsersSetPassSuccess: "[AdminUsers] Set Pass Success",

  AdminUsersRecentPostsRequest: "[AdminUsers] Recent Posts Request",
  AdminUsersRecentPostsSuccess: "[AdminUsers] Recent Posts Sucess",
  AdminUsersRecentPostsFailure: "[AdminUsers] Recent Posts Failure",

};

export default AdminUsersActionTypes;