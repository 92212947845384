export const AuthActionTypes = {
  AuthLoginRequest: '[Auth] Login Request',
  AuthLoginFailure: '[Auth] Login Failure',
  AuthLoginSuccess: '[Auth] Login Success',

  AuthLogoutRequest: '[Auth] Logout Request',
  AuthLogoutSuccess: '[Auth] Logout Success',
  AuthLogoutFailure: '[Auth] Logout Failure',

  AuthRefreshRequest: '[Auth] Refresh Request',
  AuthRefreshFailure: '[Auth] Refresh Failure',
  AuthRefreshSuccess: '[Auth] Refresh Success',

  AuthRefreshTokenRequest: '[Auth] Refresh Token Request',
  AuthRefreshTokenFailure: '[Auth] Refresh Token Failure',
  AuthRefreshTokenSuccess: '[Auth] Refresh Token Success',

  AuthSetStartUrl: '[Auth] Set Start URL',

  AuthAddRole: '[Auth] Add Role',
  AuthRemoveRole: '[Auth] Remove Role',

  AuthSetField: '[Auth] Set Field',

  AuthGetUserRequest: '[Auth] Get User Request',
  AuthGetUserSuccess: '[Auth] Get User Success',

  AuthSaveToken: '[Auth] Save Token',
  AuthDeleteToken: '[Auth] Delete Token',
  AuthSaveRefreshToken: '[Auth] Save RefreshToken',
  AuthDeleteRefreshToken: '[Auth] Delete RefreshToken',

  AuthSetLoggedOut: '[Auth] Set Logged Out',
};

export default AuthActionTypes;
