<template>
<div>
    <q-breadcrumbs>

      <q-breadcrumbs-el v-if="hasRole('admin')" :to="{name: 'adminPostView', params: { postId: $route.params.postId }}" >
        <q-btn dense flat color="red" size="sm" icon="build" />
      </q-breadcrumbs-el>

      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />

      <q-breadcrumbs-el v-if="$route.name === 'jobStateView'" label="States" to="/jobs/state" />
      <q-breadcrumbs-el v-if="$route.name === 'jobStateView'" :label="siteArrays && siteArrays.states && siteArrays.states[$route.params.stateCode]" :to="{name: 'jobStateList', params: { stateCode: $route.params.stateCode, pageId: 1}}" />

      <q-breadcrumbs-el v-if="$route.name === 'jobOrgTypeView'" label="Organization Types" to="/jobs/orgtype" />
      <q-breadcrumbs-el v-if="$route.name === 'jobOrgTypeView'" :label="$route.params.orgType" :to="{name: 'jobOrgTypeList', params: { orgType: $route.params.orgType, pageId: 1}}" />

      <q-breadcrumbs-el v-if="$route.name === 'jobContractTypeView'" label="Contract Types" to="/jobs/contract_type" />
      <q-breadcrumbs-el v-if="$route.name === 'jobContractTypeView'" :label="$route.params.contractType" :to="{name: 'jobContractTypeList', params: { contractType: $route.params.contractType, pageId: 1}}" />

      <q-breadcrumbs-el v-if="$route.name === 'jobDegreeView'" label="Degrees" to="/jobs/degree" />
      <q-breadcrumbs-el v-if="$route.name === 'jobDegreeView'" :label="$route.params.degree" :to="{name: 'jobDegreeList', params: { degree: $route.params.degree, pageId: 1}}" />

      <q-breadcrumbs-el v-if="$route.name === 'jobSkillView'" label="Skills" to="/jobs/skill" />
      <q-breadcrumbs-el v-if="$route.name === 'jobSkillView'" :label="$route.params.skill" :to="{name: 'jobSkillList', params: { skill: $route.params.skill, pageId: 1}}" />

      <q-breadcrumbs-el v-if="$route.name === 'jobCountryView'" label="Countries" to="/jobs/country" />
      <q-breadcrumbs-el v-if="$route.name === 'jobCountryView'" :label="siteArrays && siteArrays.countries && siteArrays.countries[$route.params.countryCode]" :to="{name: 'jobCountryList', params: { countryCode: $route.params.countryCode, pageId: 1}}" />

      <q-breadcrumbs-el label="View" icon="description" />

    </q-breadcrumbs>

<div v-if="post">
    <job-view :post="post"></job-view>

    <div class="q-py-md">
      <a v-if="post.apply.direct && post.apply.url" :href="post.apply.url" target="apply">
        <q-btn color="primary">Apply directly to Job</q-btn>
      </a>
      <q-btn v-else color="primary" :to="{name: 'jobApply', params: {jobId: post._id}}">
        Apply to Job
        <q-icon size="1.25em" right name="mail"></q-icon>
      </q-btn>
    </div>
</div>
<div v-else>
    Loading
</div>

</div>
</template>

<script>
// import { AccountActionsActionTypes } from "../../store/module-account-actions/action-types";
// import { PostActionTypes } from "../../store/module-post/action-types";
import jobModule from "../../store";
import storeRecViewMixin from "src/mixins/store_rec_view.mixin";
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [
    storeRecViewMixin,
  ],
  data() {
    return {
      bookmarkSaving: false,

      collection: 'job',
      vuexCollection: 'job.recs',
      vuexPath: 'job',
      vuexPageRequestAction: jobModule.actionTypes.LoadRecordsPageRequest,
      vuexGetRecordAction: jobModule.actionTypes.GetRecordRequest,

    };
  },
  computed: {
    ...mapGetters('auth', [
      'hasRole',
    ]),
    ...mapGetters('job', [
      'hasRecId',
    ]),
    ...mapState('job', {
        loading: 'loading',
      }),
    ...mapState('site', {
        siteArrays: 'arrs',
      }),
    // ...mapState('accountActions', {
    //     isBookmarked: 'isBookmarked',
    //   }),
    post() {
      // return this.$store.getters['post/getRecById'](this.$route.params.postId);
      return this.rec;
    }
  },
  created () {
    // this.reqData();

    // this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsViewRecordRequest, { postId: this.$route.params.postId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
  },
  methods: {
    // bookmarkPost(postId) {
    //   this.bookmarkSaving = true;

    //   this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsBookmarkRecordRequest, { postId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
    // },
    // unbookmarkPost(postId) {
    //   this.bookmarkSaving = true;

    //   this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsDelBookmarkRecordRequest, { postId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
    // },
    // reqData (force) {
    //   if ( ! this.post || force) {
    //     this.$store.dispatch( 'post/' + PostActionTypes.PostGetRecordRequest, { recId: this.$route.params.postId } );
    //   }
    // },
  },
}
</script>
