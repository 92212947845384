export default {

  loading: false,
  dirty: false,

  recs: {},
  total_recs: 0,
  num_pages: 0,
  page_size: 25,

  order_field: 'created_on',
  order_direction: -1,
  pages: {

  },

  qry: {

  },

  error: null,

  // initial state

  // recs: null,
  // recIds: null,

  // resultFilter: {
  //   qry: null,
  // },

  // loading: false,
  // loaded: false,
  // error: null,

};
