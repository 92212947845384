// commit is dispatch
/* eslint-disable no-unused-vars */
import Vue from "vue";
// import { uid } from 'quasar'
import { AdminPostsActionTypes } from "./action-types";

export default {

  [AdminPostsActionTypes.AdminPostsClearRecords]: (state) => {

    Vue.set(state, 'recs', {});
    Vue.set(state, 'pages', {});

    // Vue.set(state, 'num_pages', 0);
    // Vue.set(state, 'total_recs', 0);
    // Vue.set(state, 'qry_total', 0);
  },

  [AdminPostsActionTypes.AdminPostsClearPageRecords]: (state, payload) => {
    const { page_id } = payload;

    console.log('mutation clear page called for page ', page_id);

    state.pages[page_id].forEach( recId => {
      delete state.recs[recId];
    });

    delete state.pages[page_id];
    console.log('mutation cleared out page ', page_id);
  },

  [AdminPostsActionTypes.AdminPostsLoadRecordsPageRequest]: (state, payload) => {
    state.loading = true;
  },

  [AdminPostsActionTypes.AdminPostsLoadRecordsPageSuccess]: (state, payload) => {

    state.loading = false;
    state.error = null;

    let pageIds = [];

    payload.recs.forEach( rec => {
      Vue.set(state.recs, rec._id, rec);
      pageIds.push(rec._id);
    });

    state.total_recs = payload.page_info.total_recs;
    state.num_pages = payload.page_info.num_pages;
    state.page_size = payload.page_info.page_size;
    // state.qry = payload.qry;

    Vue.set(state, 'qry',  payload.qry);
    Vue.set(state, 'qry_total', payload.page_info.qry_total);
    Vue.set(state.pages,  payload.page_info.page_id,  pageIds);

  },


  [AdminPostsActionTypes.AdminPostsGetRecordRequest]: (state, payload) => {
    state.loading = true;
  },

  [AdminPostsActionTypes.AdminPostsGetRecordSuccess]: (state, payload) => {
    state.loading = false;
    if (state.recs == null) {
      Vue.set(state, 'recs', { [payload._id]: payload });
    } else {
      Vue.set(state.recs, payload._id, payload);
    }
  },

  // OLD Mutations ...

  // [AdminPostsActionTypes.AdminPostsLoadMetaRequest]: (state, payload) => {
  //   state.loading = true;
  //   state.error = null;
  // },

  // [AdminPostsActionTypes.AdminPostsLoadMetaSuccess]: (state, payload) => {
  //   state.loading = false;
  //   state.error = null;

  //   let data = {};
  //   let ids = [];
  //   payload.recs.forEach( rec => {
  //     data[ rec._id ] = { loaded: false, meta: JSON.parse(JSON.stringify(rec)), data: null };
  //     ids.push(rec._id);
  //   });
  //   Vue.set(state, "recs", data);
  //   Vue.set(state, "recIds", ids);

  //   state.loaded = true;
  // },

  // [AdminPostsActionTypes.AdminPostsPreloadPageRecordsSuccess]: (state, payload) => {
  //   state.loading = false;
  //   state.error = null;

  //   let data = state.recs;
  //   let ids = state.recIds;

  //   payload.recs.forEach( rec => {
  //     if (data[rec._id]) {
  //       data[rec._id].loaded = true;
  //       data[rec._id].data = JSON.parse(JSON.stringify(rec));
  //     }
  //   });
  // },

  // [AdminPostsActionTypes.AdminPostsClearRecords]: (state, payload) => {
  //   payload.ids.forEach( rId => {
  //     state.recs[rId].data = null;
  //     state.recs[rId].loaded = false;
  //   });
  // },

  // [AdminPostsActionTypes.AdminPostsGetRecordRequest]: (state, payload) => {
  //   state.loading = true;
  // },

  // [AdminPostsActionTypes.AdminPostsGetRecordSuccess]: (state, payload) => {
  //   state.loading = false;
  //   if (state.recs === null) {
  //     Vue.set(state, 'recs', { [payload._id]: { loaded: true, data: payload, meta: payload} });
  //     Vue.set(state, 'recIds', [ payload._id ]);
  //   } else {
  //     if (! state.recs[payload._id]) {
  //       Vue.set(state, 'recIds', [ ...state.recIds, payload._id]);
  //     }

  //     Vue.set(state.recs, payload._id, { loaded: true, data: payload, meta: payload });
  //   }
  // },


  // [AdminPostsActionTypes.AdminPostsLoadRecordsRequest]: (state, payload) => {
  //   state.loading = true;
  //   state.error = null;
  // },

  // [AdminPostsActionTypes.AdminPostsLoadRecordsSuccess]: (state, payload) => {
  //   state.loading = false;
  //   state.loaded = true;
  //   state.error = null;

  //   let postData = {};
  //   payload.data.recs.forEach( rec => {
  //     postData[ rec._id ] = rec;
  //   });
  //   Vue.set(state, "posts", postData);
  //   Vue.set(state, "postIds", payload.data.recs.map(r => r._id));
  // },


  // [AdminPostsActionTypes.AdminPostsSetFilterQry]: (state, payload) => {
  //   Vue.set(state.resultFilter, 'qry', payload.qry );
  //   Vue.set(state.resultFilter, 'field', payload.field );
  // },



  // [AdminPostsActionTypes.AdminPostsDeleteRecordsSuccess]: (state, payload) => {
  //   state.loading = false;
  //   state.error = null;

  //   payload.ids.forEach( id => {
  //     delete state.posts[id];
  //   });
  //   Vue.set(state, "postIds", Object.keys(state.posts));
  // },

};