import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import AuthActionTypes from 'src/modules/auth/store/action-types';

// import store from "../store";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  try {
    return originalPush.call(this, location).catch(err => {
      if (err.name != 'NavigationDuplicated') {
        console.log('VueRouter error', err);
        throw(err);
      }
    })
  } catch (error) {
    console.log('VueRouter error', error);
    console.log(error)
  }
}


Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

// export default function (/* { store, ssrContext } */) {
//   const Router = new VueRouter({
//     scrollBehavior: () => ({ x: 0, y: 0 }),
//     routes,

//     // Leave these as they are and change in quasar.conf.js instead!
//     // quasar.conf.js -> build -> vueRouterMode
//     // quasar.conf.js -> build -> publicPath
//     mode: process.env.VUE_ROUTER_MODE,
//     base: process.env.VUE_ROUTER_BASE
//   })

//   return Router
// }

export default ( { store } /*, ssrContext } */) => {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });

  Router.beforeEach((to, from, next) => {
    console.log('before each', to.fullPath);
    if (to.matched.some(record => record.meta.requiresAuth)) {
      console.log('in requiresAuth');
      if ( store.getters['auth/isLoggedIn'] !== true ) {
        console.log('before each NOT LOGGED IN');
        store.dispatch(`auth/${AuthActionTypes.AuthSetStartUrl}`, to.fullPath);
        next({
          path: "/auth/login",
          query: { nextUrl: to.fullPath },
          params: {
            nextUrl: to.fullPath,
          },
        });
      } else {
        console.log('they\'re logged in');
        // they're logged in.  Check if require roles and a role matches us
        to.matched.map( record => {
          if (record.meta.requiredRoles) {
            if (! record.meta.requiredRoles.some( role => store.getters['auth/hasRole'](role) )) {
              // console.log('AUTH FAILED', record.meta.requiredRoles, store.getters['auth/hasRole']('admin'), store.state );
              next({ path: "/account/home", query: { error: 'Authorization Failure', errorMsg: 'You need to have one of the following roles: ' + record.meta.requiredRoles + ' visit the FAQ for more information.' } });
            }
          }
        });

        console.log('fell out');

        next();
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if (localStorage.getItem("jwt") == null) {
        next();
      } else {
        next({
          name: "userboard",
        });
      }
    } else {
      next();
    }
  });



  return Router;
}
