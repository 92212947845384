export default {

  loading: false,
  dirty: false,

  recs: {},
  totals: {},
  total_recs: 0,
  num_pages: 0,
  page_size: 25,

  order_field: 'created_on',
  order_direction: -1,
  pages: {

  },

  qry: {
    field: null,
    val: null,
  },

  error: null,

}