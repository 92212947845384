<template>
<div v-if="post">
    <div class="q-mt-lg text-h5">
      {{post.headline}}
      <q-btn
        :shadow="false"
        size="sm"
        color="secondary"
        icon="bookmark"
        round
        @click="saveBookmark(post._id)"
      >
      </q-btn>
    </div>

    <div class="q-mb-sm text-weight-medium text-grey-8">
      <span>Posted {{ post.posted_on | date }}</span>
      &middot;
      <q-icon name="fal fa-building" />
      <router-link v-if="post.organization && post.organization._id" :to="{name: 'orgView', params: {recId: post.organization._id}}">
        {{post.organization.name}}
      </router-link>
      <span v-if="!post.organization || ! post.organization._id">
        {{post.organization.name}}
      </span>
      &middot;
      <span v-if="post.location">
        <q-icon name="place" />
        {{post.location.city}},
        <span v-show="post.location.state_code">
          <router-link class="text-primary" :to="{name: 'jobStateList', params: { pageId: 1, stateCode: post.location.state_code }}">
            {{post.location.state_code}}
          </router-link>
        </span>
          <router-link class="text-primary" :to="{name: 'jobCountryList', params: { pageId: 1, countryCode: post.location.country_code }}">
           {{siteArrays && siteArrays.countries[ post.location.country_code ]}}
          </router-link>
      </span>

    </div>

<social-sharing v-if="sharing" :url="'https://www.jewishstaffing.com/jobs/view/' + post._id"
  :title="post.headline"
  :description="'Seeking ' + post.position"

  inline-template>
    <div class="q-mb-sm q-py-sm flex justify-start q-gutter-x-sm">
      <div class="text-body1 text-weight-bold q-py-xs">
        Share:
      </div>
      <network network="facebook">
        <q-chip square dense clickable>
          <q-avatar color="primary" text-color="white" icon="fab fa-facebook-f" />
          Facebook &nbsp;
          <q-icon name="far fa-share-alt" />
        </q-chip>
      </network>
      <network network="linkedin">
        <q-chip square dense clickable>
          <q-avatar color="primary" text-color="white" icon="fab fa-linkedin-in" />
          LinkedIn
          &nbsp;
          <q-icon name="far fa-share-alt" />
        </q-chip>
      </network>
      <network network="twitter">
        <q-chip square dense clickable>
          <q-avatar color="primary" text-color="white" icon="fab fa-twitter" />
          Twitter
          &nbsp;
          <q-icon name="far fa-share-alt" />
        </q-chip>
      </network>
      <network network="whatsapp">
        <q-chip square dense clickable>
          <q-avatar color="primary" text-color="white" icon="fab fa-whatsapp" />
          Whatsapp
          &nbsp;
          <q-icon name="far fa-share-alt" />
        </q-chip>
      </network>
    </div>
</social-sharing>


    <div class="striped">

    <div class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Position:</span>
      </div>
      <div class="col q-py-xs">
        {{post.position}}
      </div>
    </div>

    <div class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Contract Type:</span>
      </div>
      <div class="col">
        <router-link :to="{name: 'jobContractTypeList', params: {contractType: post.contract_type, pageId: 1}}">
          <q-chip size=".85em">
            {{post.contract_type}}
            <q-icon right color="primary" name="search" clickable />
          </q-chip>
        </router-link>
      </div>
    </div>

    <div class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Resume:</span>
      </div>
      <div class="col q-py-xs">
        <span v-if="post.require_resume">Required</span>
        <span v-else>Optional</span>
      </div>
    </div>

    <div v-if="post.organization && post.organization._id" class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Organization:</span>
      </div>
      <div class="col q-py-xs">
        {{post.organization.name}}
      </div>
    </div>

    <div v-if="post.salary && post.salary.min" class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Salary:</span>
      </div>
      <div class="col q-py-xs">
        {{post.salary.min | currency}}
        <span v-if="post.salary.max"> - {{post.salary.max | currency}}</span>

        {{post.salary.pay_type}}
      </div>
    </div>

    <div v-if="post.experience" class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Experience:</span>
      </div>
      <div class="col q-py-xs">
        {{post.experience}}
      </div>
    </div>

    <div v-if="post.skills && post.skills.length > 0" class="row q-pa-xs flex items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
        <span class="text-body2 text-weight-bold">Skills:</span>
      </div>
      <div class="col">
        <router-link v-for="skill in post.skills" :key="skill" :to="{name: 'jobSkillList', params: {skill, pageId: 1}}">
          <q-chip size=".85em">
            {{skill}}
            <q-icon right color="primary" name="search" clickable />
          </q-chip>
        </router-link>
      </div>
    </div>

    <div v-if="post.org_types && post.org_types.length > 0" class="row q-pa-xs items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
      <span class="text-body2 text-weight-bold">Organization Type:</span>
      </div>
      <div class="col">
        <router-link v-for="orgType in post.org_types" :key="orgType" :to="{name: 'jobOrgTypeList', params: {orgType, pageId: 1}}">
          <q-chip  size=".85em">
            {{orgType}}
            <q-icon right color="primary" name="search" clickable />
          </q-chip>
        </router-link>
      </div>
    </div>

    <div v-if="post.degrees && post.degrees.length > 0" class="row q-pa-xs items-start">
      <div class="col-auto q-pa-xs" style="width: 200px;">
      <span class="text-body2 text-weight-bold">Degrees</span>
      </div>
      <div class="col">
        <router-link v-for="degree in post.degrees" :key="degree" :to="{name: 'jobDegreeList', params: {degree, pageId: 1}}">
          <q-chip size=".85em">
            {{degree}}
            <q-icon right color="primary" name="search" clickable />
          </q-chip>
        </router-link>
      </div>
    </div>

    </div>

    <q-separator />

    <div class="q-mb-xl q-pa-md" >
      <q-markdown :src="post.description || ''" />
    </div>


     <div class="row">
      <div class="col-12 q-px-sm">
        <show-data :data="post"></show-data>
      </div>
    </div>


</div>
</template>

<script>
// import { AccountActionsActionTypes } from "../store/module-account-actions/action-types";
// import { PostActionTypes } from "../store/module-post/action-types";
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'JobView',
  props: {
    'sharing': {
      type: Boolean,
      default: true,
      required: false
    },
    'post': {
      type: Object,
      default: null,
      required: true
    },
  },
  data() {
    return {
      bookmarkSaving: false,
    };
  },
  computed: {
    ...mapState('site', {
      siteArrays: 'arrs',
    } ),
  },
  methods: {
    // searchSkill(skill) {
    //   this.$store.dispatch( 'post/' + PostActionTypes.PostAddFilterSkill, skill ).then( () => {
    //     this.$router.push({name: 'jobSearch', params: {pageId: 1}})
    //   });


    // },
    // saveBookmark(postId) {
    //   this.bookmarkSaving = true;

    //   this.$store.dispatch( 'accountActions/' + AccountActionsActionTypes.AccountActionsBookmarkRecordRequest, { postId } ).finally( () => {
    //     this.bookmarkSaving = false;
    //   });
    // },
  },
}
</script>
