import { AdminOnboardingOrgsActionTypes } from "./action-types";
// import ApiService from "../../../services/api.service";
// import { notifyError } from '../../../services/utils';

export default {
  [AdminOnboardingOrgsActionTypes.AdminOnboardingOrgsInviteRecordsSuccess]: async ({ commit }, payload) => {
    commit(AdminOnboardingOrgsActionTypes.AdminOnboardingOrgsInviteRecordsSuccess, payload);
  },

};
