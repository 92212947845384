<template>
<div v-if="hasRole('debug')">

   <q-tabs
      v-model="dataTab"
      dense
      class="bg-grey-3"
      active-color="primary"
      indicator-color="primary"
      align="left"
      narrow-indicator
    >
      <q-tab name="table" label="Table" />
      <q-tab name="json" label="JSON" />
    </q-tabs>

    <q-separator />

    <q-tab-panels v-model="dataTab" animated>
      <q-tab-panel v-if="data" name="table">
        <table class="table table-sm table-striped full-width">
          <tr v-for="keyPath in dataKeys.sort()" :key="keyPath">
            <th class="text-left text-weight-regular">{{keyPath}}</th>
            <td>{{ getPathValue(keyPath) }}</td>
          </tr>
        </table>
      </q-tab-panel>

      <q-tab-panel name="json">
        <pre>
{{ data }}
        </pre>
      </q-tab-panel>
    </q-tab-panels>

</div>
</template>
<style scoped>
</style>
<script>
import { mapGetters } from 'vuex';
import * as DotObject from 'dot-object';
/* eslint-disable no-unused-vars */
import { get as loadashGet } from 'lodash';

export default {
  name: 'ShowData',
  props: {
    'data': {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      dataTab: 'table',
    }
  },
  computed: {
    ...mapGetters('auth', [
      'hasRole',
    ]),
    dataKeys() {
      return this.data ? Object.keys( DotObject.dot(this.data) ) : [];
    },
  },
  methods: {
    getPathValue(path) {
      return loadashGet(this.data, path);
    },

  },
}
</script>

