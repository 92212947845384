export const AdminActionTypes = {

  AdminFieldOnRequest: "[Admin] Field On Request",
  AdminFieldOnSuccess: "[Admin] Field On Success",
  AdminFieldOnFailure: "[Admin] Field On Failure",

  AdminFieldOffRequest: "[Admin] Field Off Request",
  AdminFieldOffSuccess: "[Admin] Field Off Success",
  AdminFieldOffFailure: "[Admin] Field Off Failure",

  AdminFieldSetRequest: "[Admin] Field Set Request",
  AdminFieldSetSuccess: "[Admin] Field Set Success",
  AdminFieldSetFailure: "[Admin] Field Set Failure",

  AdminFieldAddDaysRequest: "[Admin] Field AddDays Request",
  AdminFieldAddDaysSuccess: "[Admin] Field AddDays Success",
  AdminFieldAddDaysFailure: "[Admin] Field AddDays Failure",

  AdminFieldSubDaysRequest: "[Admin] Field SubDays Request",
  AdminFieldSubDaysSuccess: "[Admin] Field SubDays Success",
  AdminFieldSubDaysFailure: "[Admin] Field SubDays Failure",

  AdminFieldPullRequest: "[Admin] Field Pull Request",
  AdminFieldPullSuccess: "[Admin] Field Pull Success",
  AdminFieldPullFailure: "[Admin] Field Pull Failure",

  AdminFieldPushRequest: "[Admin] Field Push Request",
  AdminFieldPushSuccess: "[Admin] Field Push Success",
  AdminFieldPushFailure: "[Admin] Field Push Failure",

  AdminFieldAddToSetRequest: "[Admin] Field AddToSet Request",
  AdminFieldAddToSetSuccess: "[Admin] Field AddToSet Success",
  AdminFieldAddToSetFailure: "[Admin] Field AddToSet Failure",

  AdminSetFilter: "[Admin] Set Filter",

  AdminAddRole: "[Admin] Add Role",
  AdminRemoveRole: "[Admin] Remove Role",

};
