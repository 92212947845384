<template>
<div>
  <span class="text-blue-9">{{years}}</span>.<span class="text-green-9" style="font-size: .95em">{{months}}</span>.<span class="text-red" style="font-size: .9em">{{days}}</span>
</div>
</template>
<script>
import {date} from 'quasar';

export default {
  name: 'Age',
  props: {
    'val': {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    years() {
      let days = date.getDateDiff(new Date(), new Date(this.val), 'days');
      return parseInt(days / 365);
    },

    months() {
      let days = date.getDateDiff(new Date(), new Date(this.val), 'days');
      days %= 365;
      return parseInt(days / 30);
    },

    days() {
      let days = date.getDateDiff(new Date(), new Date(this.val), 'days');
      days %= 365;
      days %= 30;
      return days;
    },

  },
}
</script>

