import Vue from 'vue';
import { SiteActionTypes } from './action-types';

export default {
  [SiteActionTypes.SiteArraysLoadSuccess]: (state, payload) => {
    Vue.set(state, 'brand', payload.brand);
    Vue.set(state, 'version', payload.version);
    Vue.set(state, 'arrs', payload);
  },


  [SiteActionTypes.SiteArraysSetVersion]: (state, payload) => {
    Vue.set(state, 'version', payload);
  },

};
