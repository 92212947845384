const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
// import { Store }  from "../store";
// import { AuthActionTypes } from 'src/modules/auth/store/action-types';
import axios from "axios";

/*
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 */
const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
    // return Store.state.auth.token || localStorage.getItem(TOKEN_KEY);
  },

  async saveToken(accessToken) {
    // await Store.dispatch(`auth/${AuthActionTypes.AuthSaveToken}`, accessToken);
    localStorage.setItem(TOKEN_KEY, accessToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  },

  async removeToken() {
    // await Store.dispatch(`auth/${AuthActionTypes.AuthDeleteToken}`);
    localStorage.removeItem(TOKEN_KEY);
  },

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

export { TokenService };
