import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import orgs from './module-admin-onboarding-orgs';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
  modules: {
    orgs
  },
};
