// import JobListItem from "../components/job-list-item.vue";
import ShowData from '../components/show-data.vue';
// import JobView from '../components/job-view.vue';
// import OrderView from '../components/order-view.vue';

import UserName from '../components/user-name.vue';
import Age from '../components/age.vue';
import DelRec from '../components/del-rec.vue';

// import BarChart from '../components/bar-chart';

export default async ({ Vue }) => {
  // Vue.component(JobListItem.name, JobListItem);
  Vue.component(ShowData.name, ShowData);
  // Vue.component(JobView.name, JobView);
  // Vue.component(OrderView.name, OrderView);
  Vue.component(UserName.name, UserName);
  Vue.component(Age.name, Age);
  Vue.component(DelRec.name, DelRec);
  // Vue.component(BarChart.name, BarChart);
};
