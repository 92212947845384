
const routes = [

  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [

      {
        path: "admin",
        meta: { requiresAuth: true, requiredRoles: ['admin'] },
        component: () => import( /* webpackChunkName: "group-admin" */ "./pages/admin/admin-base.vue"),
        children: [
          { path: "home", name: 'adminHome', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-home.vue") },
          { path: "stats", name: 'adminStats', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-stats.vue") },

          { path: "users/list/:pageId", name: 'adminUsersList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-users/admin-users-list.vue") },
          { path: "users/view/:recId", name: 'adminUsersView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-users/admin-users-view.vue") },


          { path: "orders/list/:pageId", name: 'adminOrdersList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-orders/admin-orders-list.vue") },
          { path: "orders/view/:recId", name: 'adminOrdersView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-orders/admin-orders-view.vue") },

          { path: "orgs/list/:pageId", name: 'adminOrgsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-orgs/admin-orgs-list.vue") },
          { path: "orgs/view/:recId", name: 'adminOrgView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-orgs/admin-orgs-view.vue") },

          { path: "coupons/list/:pageId", name: 'adminCouponsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-coupons/admin-coupons-list.vue") },
          { path: "coupons/view/:recId", name: 'adminCouponView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-coupons/admin-coupons-view.vue") },
          { path: "coupons/add", name: 'adminCouponsAdd', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-coupons/admin-coupons-add.vue") },

          { path: "feedback/list/:pageId", name: 'adminFeedbackList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-feedback/admin-feedback-list.vue") },
          { path: "feedback/view/:recId", name: 'adminFeedbackView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-feedback/admin-feedback-view.vue") },

          { path: "server-errors/list/:pageId", name: 'adminServerErrorsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-server-errors/admin-server-errors-list.vue") },
          { path: "server-errors/view/:recId", name: 'adminServerErrorsView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-server-errors/admin-server-errors-view.vue") },

          { path: "js-errors/list/:pageId", name: 'adminJSErrorsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-js-errors/admin-js-errors-list.vue") },
          { path: "js-errors/view/:recId", name: 'adminJSErrorsView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-js-errors/admin-js-errors-view.vue") },


          {
            path: 'posts',
            alias: "jobs",
            meta: { padding: false},
            component: () => import( /* webpackChunkName: "group-admin" */ "./pages/admin/admin-posts/admin-posts-base.vue"),

            children: [
              { path: "home", name: 'adminPostsHome', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-posts/admin-posts-home.vue") },

              { path: "list/:pageId", name: 'adminPostsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-posts/admin-posts-list.vue") },
              { path: "view/:recId", name: 'adminPostView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-posts/admin-posts-view.vue") },


              { path: "pending/list/:pageId", name: 'adminPostsPendingList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-pending-posts/admin-pending-posts-list.vue") },
              { path: "pending/view/:recId", name: 'adminPostsPendingView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-pending-posts/admin-pending-posts-view.vue") },



              { path: "applications/list/:pageId", name: 'adminJobApplicationsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-posts/admin-jobs-applications/admin-jobs-applications-list.vue") },
              // { path: "applications/view/:appId", name: 'adminJobApplicationView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-posts/admin-jobs-applications/admin-jobs-applications-view.vue") },


            ],

          },

          // {
          //   path: 'onboarding',
          //   meta: { padding: false},
          //   component: () => import( /* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-base.vue"),

          //   children: [
          //     { path: "home", name: 'adminOnboardingHome', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-home.vue") },

          //     { path: "crawler/list/:pageId", name: 'adminCrawlerList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-crawler/admin-crawler-list.vue") },
          //     { path: "crawler/view/:recId", name: 'adminCrawlerView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-crawler/admin-crawler-view.vue") },

          //     { path: "orgs/list/:pageId", name: 'adminOnboardingOrgList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-orgs/admin-onboarding-orgs-list.vue") },
          //     { path: "orgs/view/:recId", name: 'adminOnboardingOrgView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-orgs/admin-onboarding-orgs-view.vue") },

          //     { path: "jobs/list/:pageId", name: 'adminOnboardingJobsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-jobs/admin-onboarding-jobs-list.vue") },
          //     { path: "jobs/view/:jobId", name: 'adminOnboardingJobsView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-jobs/admin-onboarding-jobs-view.vue") },
          //   ],
          // },
          {
            path: 'marketing',
            meta: { padding: false},

            component: () => import( /* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-base.vue"),
            children: [

              { path: "home", name: 'adminMarketingHome', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-home.vue") },

          //     { path: "newsletter/home", name: 'adminMarketingNewsletterHome', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-home.vue") },

              { path: "emails/list/:pageId", name: 'adminMarketingEmailsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-emails/admin-marketing-emails-list.vue") },
          //     { path: "emails/view/:emailId", name: 'adminMarketingEmailsView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-emails/admin-marketing-emails-view.vue") },

          //     { path: "emails/add", name: 'adminMarketingEmailsAdd', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-emails/admin-marketing-emails-add.vue") },
          //     { path: "emails/edit/:emailId", name: 'adminMarketingEmailsEdit', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-emails/admin-marketing-emails-add.vue") },


              { path: "campaigns/list/:pageId", name: 'adminMarketingCampaignsList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-campaigns/admin-marketing-campaigns-list.vue") },

              { path: "campaigns/view/:recId", name: 'adminMarketingCampaignsView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-campaigns/admin-marketing-campaigns-view.vue") },

          //     { path: "campaigns/add", name: 'adminMarketingCampaignsAdd', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-campaigns/admin-marketing-campaigns-add.vue") },
          //     { path: "campaigns/edit/:campaignId", name: 'adminMarketingCampaignsEdit', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-marketing/admin-marketing-campaigns/admin-marketing-campaigns-add.vue") },

            ],
          },

          // // { path: "onboarding/jobseekers/list/:pageId", name: 'adminOnboardingJobseekerList', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-jobseekers/admin-onboarding-jobseekers-list.vue") },
          // // { path: "onboarding/jobseekers/view/:jobseekerId", name: 'adminOnboardingJobseekerView', component: () => import(/* webpackChunkName: "group-admin" */ "./pages/admin/admin-onboarding/admin-onboarding-jobseekers/admin-onboarding-jobseekers-view.vue") },

          // { path: "*", component: () => import("src/pages/Error404.vue") },
        ],
      },

    ],
  },

];

export default routes;
