
const routes = [

  {
    path: '/',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [
      {
        path: "organizations",
        component: () => import("./pages/organizations/org-base.vue"),
        children: [

          { name: 'orgList', path: "list/:pageId", component: () => import("./pages/organizations/org-list.vue") },
          { name: 'orgView', path: "view/:recId", component: () => import("./pages/organizations/org-view.vue") },
          // { name: 'orgAdd', path: "add", meta: { requiresAuth: true, }, component: () => import("./pages/organizations/org-add.vue") },
          // { name: 'orgEdit', path: "edit/:orgId", meta: { requiresAuth: true, }, component: () => import("./pages/organizations/org-add.vue") },


          { name: 'orgStateTotals', path: "state", component: () => import("./pages/organizations/org-state/org-state-totals.vue") },
          { name: 'orgStateList', path: "state/:stateCode/:pageId", component: () => import("./pages/organizations/org-state/org-state-list.vue") },
          { name: 'orgStateView', path: "state/:stateCode/view/:recId", component: () => import("./pages/organizations/org-view.vue") },

          // { name: 'orgCountryTotals', path: "country", component: () => import("./pages/organizations/org-country/org-country-totals.vue") },
          // { name: 'orgCountryList', path: "country/:countryCode/:pageId", component: () => import("./pages/organizations/org-country/org-country-list.vue") },
          // { name: 'orgCountryView', path: "country/:countryCode/view/:recId", component: () => import("./pages/organizations/org-view.vue") },

        ],
      },
    ],
  },

];

export default routes;
