// commit is dispatch

import { AdminJobApplicationsActionTypes } from "./action-types";
import ApiService from "src/services/api.service";
import { notifyError, notifySuccess }  from "src/services/utils";

export default {

  [AdminJobApplicationsActionTypes.AdminJobApplicationsResendApp]: async (_, payload) => {

    try {
      const apiData = await ApiService.get('/api/admin/posts/applications/resend/' + payload.appId);
      console.log('apiData', apiData);
      notifySuccess('Resent App');
      return true;
    } catch (error) {
      console.log("caught error in get app record", error.response);
      notifyError('Resend App', error);
      throw error;
    }

  },

};
