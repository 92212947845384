<template>
<div class="del_rec">

  <q-btn v-bind="btnProps" :label="label" @click="showDialog = true">
    <slot>
      Remove Rec
    </slot>
  </q-btn>

  <q-dialog v-model="showDialog" v-bind="dialogProps">
    <slot name="dialog">
      <q-card class="q-py-sm" style="width: 350px">

       <q-card-section class="row items-start justify-start">
          <div class="col-auto q-pa-sm">
            <q-icon name="warning" color="negative" size="xl" />
          </div>
          <div class="col q-pa-sm text-weight-medium">
            Are you sure you want to remove this record?
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="grey" v-close-popup />
          <q-btn label="Confirm Delete" color="negative" @click="btnClick" />
        </q-card-actions>
      </q-card>
    </slot>
  </q-dialog>

</div>
</template>
<style scoped>
.del_rec {
  display: inline;
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DelRec',
  props: {
    'recId': {
      type: String,
      default: null,
    },
    'label': {
      type: String,
      default: null,
      required: false
    },
    'btnProps': {
      type: Object,
      default: null,
      required: false
    },
    'dialogProps': {
      type: Object,
      default: null,
      required: false
    },
    'action': {
      type: String,
      default: null,
      required: true
    },

  },
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'hasRole',
    ]),
    userRoute() { return this.hasRole('admin') ? 'adminUsersView'  : 'usersView'; },
  },
  methods: {
    btnClick() {
      this.$store.dispatch(this.action, { recId: this.recId, onSuccess: this.onSuccess });
    },
    onSuccess() {
      this.$emit("removed", { recId: this.recId });
    }
  },
}
</script>

