import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import emails from './module-admin-marketing-emails';
import campaigns from './module-admin-marketing-campaigns';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
  modules: {
    emails,
    campaigns,
  }
};
