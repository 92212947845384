export const AdminOnboardingActionTypes = {

  AdminOnboardingLoadOrgRecordsRequest: "[AdminOnboarding] Load Org Records Request",
  AdminOnboardingLoadOrgRecordsSuccess: "[AdminOnboarding] Load Org Records Success",
  AdminOnboardingLoadOrgRecordsFailure: "[AdminOnboarding] Load Org Records Failure",

  AdminOnboardingLoadJobRecordsRequest: "[AdminOnboarding] Load Job Records Request",
  AdminOnboardingLoadJobRecordsSuccess: "[AdminOnboarding] Load Job Records Success",
  AdminOnboardingLoadJobRecordsFailure: "[AdminOnboarding] Load Job Records Failure",

  AdminOnboardingJobGetRecordRequest: "[AdminOnboarding] Get Job Record Request",
  AdminOnboardingJobGetRecordFailure: "[AdminOnboarding] Get Job Record Failure",
  AdminOnboardingJobGetRecordSuccess: "[AdminOnboarding] Get Job Record Success",

  AdminOnboardingJobRecordsImportRequest: "[AdminOnboarding] Import Job Records Request",
  AdminOnboardingJobRecordsImportSuccess: "[AdminOnboarding] Import Job Records Success",
  AdminOnboardingJobRecordsImportFailure: "[AdminOnboarding] Import Job Records Failure",

  AdminOnboardingLoadJobseekerRecordsRequest: "[AdminOnboarding] Load Jobseeker Records Request",
  AdminOnboardingLoadJobseekerRecordsSuccess: "[AdminOnboarding] Load Jobseeker Records Success",
  AdminOnboardingLoadJobseekerRecordsFailure: "[AdminOnboarding] Load Jobseeker Records Failure",


  AdminOnboardingSetFilter: "[AdminOnboarding] Set Filter",
  AdminOnboardingSetOrgFilterQry: "[AdminOnboarding] Set Org Filter Query",
  AdminOnboardingSetJobFilterQry: "[AdminOnboarding] Set Job Filter Query",
  AdminOnboardingSetJobseekerFilterQry: "[AdminOnboarding] Set Jobseeker Filter Query",

  AdminOnboardingAddFilterSkipImported: "[AdminOnboarding] Set Filter Skip Imported",
  AdminOnboardingAddFilterSkipReviewed: "[AdminOnboarding] Set Filter Skip Reviewed",

  AdminOnboardingSetOrgRecordsReviewedRequest: "[AdminOnboarding] Set Org Records Reviewed Request",
  AdminOnboardingSetOrgRecordsReviewedSuccess: "[AdminOnboarding] Set Org Records Reviewed Success",
  AdminOnboardingSetOrgRecordsReviewedFailure: "[AdminOnboarding] Set Org Records Reviewed Failure",

  AdminOnboardingInviteOrgRecordsRequest: "[AdminOnboarding] Invite Org Records Request",
  AdminOnboardingInviteOrgRecordsSuccess: "[AdminOnboarding] Invite Org Records Success",
  AdminOnboardingInviteOrgRecordsFailure: "[AdminOnboarding] Invite Org Records Failure",

  AdminOnboardingOrgGetRecordRequest: "[AdminOnboarding] Get Org Record Request",
  AdminOnboardingOrgGetRecordFailure: "[AdminOnboarding] Get Org Record Failure",
  AdminOnboardingOrgGetRecordSuccess: "[AdminOnboarding] Get Org Record Success",


};
