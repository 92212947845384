// commit is dispatch

import { SiteActionTypes } from './action-types';
import ApiService from 'src/services/api.service';
import { notifyError } from 'src/services/utils';

export default {
  [SiteActionTypes.SiteArraysLoadRequest]: async ({ commit }) => {
    // commit(SiteActionTypes.SiteArraysLoadRequest, payload);
    try {
      const apiData = await ApiService.get('/api/fetch_arrays');
      commit(SiteActionTypes.SiteArraysLoadSuccess, apiData.data);
      return true;
    } catch (error) {
      console.log('caught error in get job record', error.response);
      notifyError('Get Job Record Request', error);
      throw error;
    }
  },


  [SiteActionTypes.SiteArraysSetVersion]: async ({ commit }, payload) => {
    commit(SiteActionTypes.SiteArraysSetVersion, payload);
  },

};
