// required
import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
// do you want these too?
import '@fortawesome/fontawesome-pro/css/brands.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import '@fortawesome/fontawesome-pro/css/regular.min.css';

// "async" is optional
/* eslint-disable no-empty-pattern */
export default async (
  {
    /* app, router, Vue, ... */
  }
) => {
  // something to do
};
