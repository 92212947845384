export default {
  hasJobId: state => jobId => state.jobIds !== null ? state.jobIds.includes(jobId) : null,
  getJobById: state => jobId => state.jobs !== null ? state.jobs[jobId] : null,

  hasJobseekerId: state => jobseekerId => state.jobseekerIds !== null ? state.jobseekerIds.includes(jobseekerId) : null,
  getJobseekerById: state => jobseekerId => state.jobseekers !== null ? state.jobseekers[jobseekerId] : null,

  hasOrgId: state => orgId => state.orgIds !== null ? state.orgIds.includes(orgId) : null,
  getOrgById: state => orgId => state.orgs !== null ? state.orgs[orgId] : null,

  filteredOrgIds: state => {
    // if (state.orgFilter.qry === null) {
    //   return state.orgIds;
    // }
    if (state.orgIds === null) {
      return [];
    }

    const terms = state.orgFilter.qry ? state.orgFilter.qry.split(' ') : [];

    return Object.keys(state.orgs).filter( oid => {

      if ( state.orgFilter.nonImported === true && state.orgs[oid].imported === true ) {
        return false;
      }

      if ( state.orgFilter.nonReviewed === true && state.orgs[oid].reviewed === true ) {
        return false;
      }

      for (var i = 0; i < terms.length; i++) {
        var matched = false;

        if (
            ( state.orgs[oid].contact &&
            state.orgs[oid].contact.email.toLowerCase().indexOf(terms[i]) != -1) ||
            (
              state.orgs[oid].name &&
              state.orgs[oid].name.toLowerCase().indexOf(terms[i]) != -1
            )
        ) {
          matched = true;
        }

        if (! matched) {
          return false;
        }

      }

      return true;
    });
  },

  filteredJobIds: state => {
    console.log('filteredJobIds', state.jobIds);
    if (state.jobFilter.qry === null) {
      return state.jobIds;
    }

    const terms = state.jobFilter.qry.split(' ');
    return Object.keys(state.jobs).filter( uid => {

      for (var i = 0; i < terms.length; i++) {
        var matched = false;

        if (state.jobFilter.field) {
            if (state.jobs[uid][ state.jobFilter.field ] &&
                state.jobs[uid][ state.jobFilter.field ].toLowerCase().indexOf(terms[i]) != -1) {
                  matched = true;
                }
        } else{
          if (
              state.jobs[uid].headline.toLowerCase().indexOf(terms[i]) != -1 ||
              (
                state.jobs[uid].location &&
                state.jobs[uid].location.city &&
                state.jobs[uid].location.city.toLowerCase().indexOf(terms[i]) != -1
              ) ||
              (
                state.jobs[uid].organization &&
                state.jobs[uid].organization.name &&
                state.jobs[uid].organization.name.toLowerCase().indexOf(terms[i]) != -1
              )
          ) {
            matched = true;
          }

        }

        if (! matched) {
          return false;
        }

      }

      return true;
    });
  },

  filteredJobseekerIds: state => {
    if (state.jobseekerFilter.qry === null) {
      return state.jobseekerIds;
    }

    const terms = state.jobseekerFilter.qry.split(' ');

    return Object.keys(state.jobseekers).filter( oid => {

      for (var i = 0; i < terms.length; i++) {
        var matched = false;

        if (
            state.jobseekers[oid].post.headline.toLowerCase().indexOf(terms[i]) != -1 ||
            state.jobseekers[oid].post.coupon.toLowerCase().indexOf(terms[i]) != -1
        ) {
          matched = true;
        }

        if (! matched) {
          return false;
        }
      }
      return true;
    });
  },


  totalJobseekerRecords: state => state.jobseekerIds !== null ? state.jobseekerIds.length : 0,
  totalFilteredJobseekerRecords: (state, getters) => getters.filteredJobseekerIds !== null ? getters.filteredJobseekerIds.length : 0,

  totalJobRecords: state => state.jobIds !== null ? state.jobIds.length : 0,
  totalFilteredJobRecords: (state, getters) => getters.filteredJobIds !== null ? getters.filteredJobIds.length : 0,

  totalOrgRecords: state => state.orgIds !== null ? state.orgIds.length : 0,
  totalFilteredOrgRecords: (state, getters) => getters.filteredOrgIds !== null ? getters.filteredOrgIds.length : 0,

  getJobseekerPageSet: state => (pageId, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 100;
    }
    let start = (pageId - 1) * pageSize;
    if (! state.jobseekers) {
      return [];
    }
    return state.jobseekerIds.slice(start, start + pageSize).map(rid => state.jobseekers[rid]);
  },

  getFilteredJobseekerPageSet: (state, getters) => (pageId, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 100;
    }
    let start = (pageId - 1) * pageSize;
    if (! state.jobseekers) {
      return [];
    }
    return getters.filteredJobseekerIds.slice(start, start + pageSize).map(rid => state.jobseekers[rid]);
  },

  getJobPageSet: state => (pageId, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 100;
    }
    let start = (pageId - 1) * pageSize;
    if (! state.jobs) {
      return [];
    }
    return state.jobIds.slice(start, start + pageSize).map(rid => state.jobs[rid]);
  },

  getFilteredJobPageSet: (state, getters) => (pageId, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 100;
    }
    let start = (pageId - 1) * pageSize;
    if (! state.jobs) {
      return [];
    }
    return  getters.filteredJobIds.slice(start, start + pageSize).map(rid => state.jobs[rid]);
  },

  getOrgPageSet: state => (pageId, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 100;
    }
    let start = (pageId - 1) * pageSize;
    if (! state.orgs) {
      return [];
    }
    return state.orgIds.slice(start, start + pageSize).map(rid => state.orgs[rid]);
  },

  getFilteredOrgPageSet: (state, getters) => (pageId, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 100;
    }
    let start = (pageId - 1) * pageSize;
    if (! state.orgs) {
      return [];
    }
    return getters.filteredOrgIds.slice(start, start + pageSize).map(rid => state.orgs[rid]);
  },

};
