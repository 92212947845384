import ApiService from "./api.service";
import { TokenService } from "./storage.service";
import { Store }  from "src/store";
import { AuthActionTypes } from 'src/modules/auth/store/action-types';

class AuthenticationError extends Error {
  constructor(errorCode, message, data) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.data = data;
  }
}

const AuthService = {
  /*
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   */
  login: async (email, password) => {
    try {
      const response = await ApiService.post("/jwt/auth", { email, password });

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      ApiService.setHeader();

      // put it in the store
      Store.dispatch(`auth/${AuthActionTypes.AuthRefreshTokenSuccess}`, response.data);

      ApiService.mount401Interceptor();

      return response.data;
    } catch (error) {
      console.log("caught error in login", error.response);
      throw new AuthenticationError(error.response.status, error.response.data.message, error.response.data);
    }
  },

  /*
   * Refresh the access token.
   */
  refreshToken: async () => {
    const refreshToken = TokenService.getRefreshToken();
    if (! refreshToken) {
      console.log('no refresh token in localstorage');
      return false;
    }

    try {
      const response = await ApiService.post("/jwt/auth/refresh", {
        refresh_token: refreshToken,
      });

      TokenService.saveToken(response.data.access_token);
      // TokenService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      ApiService.setHeader();

      Store.dispatch(`auth/${AuthActionTypes.AuthRefreshTokenSuccess}`, response.data);

      return response.data;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.detail);
    }
  },

  refreshUser: async () => {
    // try {
      const response = await ApiService.get("/jwt/auth/refresh_user");
      return response.data;
    // } catch (error) {
    //   console.log('not throwing error', error);
    //   // throw new AuthenticationError(error.response.status, error.response.data.detail);
    // }
  },

  /*
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   */
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();

    // NOTE: Again, we'll cover the 401 Interceptor a bit later.
    // ApiService.unmount401Interceptor();
  },
};

export default AuthService;

export { AuthService, AuthenticationError };
