/* eslint-disable no-unused-vars */
import Vue from "vue";
import genActionTypes from "./action-types";

export default function genMutations ( name ) {

  const actionTypes = genActionTypes(name);

  return {

    [actionTypes.ClearRecords]: (state) => {

      Vue.set(state, 'recs', {});
      Vue.set(state, 'pages', {});

      // Vue.set(state, 'num_pages', 0);
      // Vue.set(state, 'total_recs', 0);
      // Vue.set(state, 'qry_total', 0);
    },

    [actionTypes.ClearPageRecords]: (state, payload) => {
      const { page_id } = payload;

      console.log('mutation clear page called for page ', page_id);

      state.pages[page_id].forEach( recId => {
        delete state.recs[recId];
      });

      delete state.pages[page_id];
      console.log('mutation cleared out page ', page_id);
    },

    [actionTypes.LoadRecordsPageRequest]: (state, payload) => {
      state.loading = true;
    },

    [actionTypes.LoadRecordsPageSuccess]: (state, payload) => {

      state.loading = false;
      state.error = null;

      let pageIds = [];

      payload.recs.forEach( rec => {
        Vue.set(state.recs, rec._id, rec);
        pageIds.push(rec._id);
      });

      state.total_recs = payload.page_info.total_recs;
      state.num_pages = payload.page_info.num_pages;
      state.page_size = payload.page_info.page_size;

      Vue.set(state, 'qry',  payload.qry);
      Vue.set(state, 'qry_total', payload.page_info.qry_total);
      Vue.set(state.pages,  payload.page_info.page_id,  pageIds);
    },


    [actionTypes.GetRecordRequest]: (state, payload) => {
      state.loading = true;
    },

    [actionTypes.DelRecordRequest]: (state, payload) => {
      state.loading = true;
    },

    [actionTypes.DelRecordSuccess]: (state, payload) => {
      state.loading = false;

      if (payload._id) {
        if (state.recs !== null) {
          Vue.delete(state.recs, payload._id);
        }

        // remove pages results
        if (state.pages) {
          Object.keys(state.pages).map( pageId => {
            let idx = state.pages[pageId].findIndex( el => el === payload._id);
            if (idx > -1) {
              Vue.delete(state.pages[pageId], idx);
            }
          })
        }
      }

    },

    [actionTypes.GetRecordSuccess]: (state, payload) => {
      state.loading = false;
      if (state.recs == null) {
        Vue.set(state, 'recs', { [payload._id]: payload });
      } else {
        Vue.set(state.recs, payload._id, payload);
      }
    },

    [actionTypes.LoadTotalsSuccess]: (state, payload) => {
      state.loading = false;
      Vue.set(state, 'totals', payload);
    },

  };

};