export default {
  // initial state

  jobseekers: null,
  jobseekerIds: null,
  jobseekerLoading: false,
  jobseekerLoaded: false,

  jobs: null,
  jobIds: null,
  jobLoading: false,
  jobLoaded: false,

  jobseekerFilter: {
    qry: null,
  },

  jobFilter: {
    qry: null,
  },

  loaded: false,
  loading: false,
  error: null,

};
