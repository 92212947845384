// commit is dispatch

import { AdminCrawlerActionTypes } from "./action-types";
import ApiService from "src/services/api.service";
import { notifyError, notifySuccess } from 'src/services/utils';

export default {

  [AdminCrawlerActionTypes.AdminCrawlerJobRecordsImportRequest]: async ({ commit }, payload) => {
    // commit(AdminCrawlerActionTypes.AdminCrawlerJobRecordsImportRequest, payload);

    try {
      const userData = await ApiService.post('/api/admin/crawler/import', payload);
      console.log("in admin crawler jobs import action", userData);
      notifySuccess('Imported ' + userData.data.data.imported.length + ' jobs');
      commit(AdminCrawlerActionTypes.AdminCrawlerJobRecordsImportSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in import crawler job records", error.response);
      notifyError('Import Crawler Job Records Request', error);
      throw error;
    }
  },


};
