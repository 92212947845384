// commit is dispatch
/* eslint-disable no-unused-vars */
import Vue from 'vue';
// import { uid } from 'quasar'
import { AuthActionTypes } from './action-types';
import { AuthService } from 'src/services/auth.service';
// import { TokenService } from "src/services/storage.service";
import axios from 'axios';

function saveAccessToken(token) {
  localStorage.setItem('access_token', token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Vue.set(state, 'posts', payload);
export default {
  [AuthActionTypes.AuthLoginRequest]: (state, payload) => {
    state.loading = true;
    state.error = null;
  },

  [AuthActionTypes.AuthLoginSuccess]: (state, payload) => {
    state.token = payload.access_token;

    localStorage.setItem('access_token', payload.access_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${payload.access_token}`;

    if (payload.refresh_token) {
      state.refreshToken = payload.refresh_token;
      localStorage.setItem('refresh_token', payload.refresh_token);
    }

    state.loading = false;
    state.loggedIn = true;
    state.error = null;
    Vue.set(state, 'user', payload.user);
    // state.user = Object.assign({}, payload.user);
  },

  [AuthActionTypes.AuthRefreshTokenSuccess]: (state, payload) => {
    console.log('**** IN AUTH REFRESH COMMIT');
    state.token = payload.access_token;
    state.loggedIn = true;
    state.loading = false;
    state.error = null;

    saveAccessToken(payload.access_token);

    // Vue.set(state, "user", payload.user);
    // state.user = Object.assign({}, payload.user);
  },

  [AuthActionTypes.AuthRefreshSuccess]: (state, payload) => {
    console.log('Auth refresh success mutation called', payload);
    // Vue.set(state, "user", payload);
    state.user = Object.assign({}, payload.me);
    state.loggedIn = true;

    state.token = payload.access_token;
    saveAccessToken(payload.access_token);

    if (payload.refresh_token) {
      state.refreshToken = payload.refresh_token;
      localStorage.setItem('refresh_token', payload.refresh_token);
    }
  },

  [AuthActionTypes.AuthGetUserSuccess]: (state, payload) => {
    console.log('Auth get user success mutation called', payload);
    state.user = Object.assign({}, payload);
  },

  [AuthActionTypes.AuthLoginFailure]: (state, payload) => {
    state.user = null;
    state.loading = false;
    state.error = payload.error;
  },

  [AuthActionTypes.AuthLogoutRequest]: () => {},

  [AuthActionTypes.AuthLogoutSuccess]: state => {
    state.user = null;
    state.token = null;
    state.refreshToken = null;

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    state.loggedIn = false;

    AuthService.logout();
  },

  [AuthActionTypes.AuthSetStartUrl]: (state, payload) => {
    state.startUrl = payload;
  },

  [AuthActionTypes.AuthAddRole]: (state, payload) => {
    state.user.roles.push(payload);
  },

  [AuthActionTypes.AuthRemoveRole]: (state, payload) => {
    state.user.roles = state.user.roles.filter(r => r !== payload);
  },

  // TODO we should check this works for basic fields w/ no dot.
  [AuthActionTypes.AuthSetField]: (state, payload) => {
    let fields = payload.key.split('.');
    const last = fields.pop();

    let ob = fields.reduce((a, b) => a[b], state);

    Vue.set(ob, last, payload.value);
  },

  [AuthActionTypes.AuthSetLoggedOut]: (state, payload) => {
    state.user = null;
    state.loggedIn = false;
  },

  [AuthActionTypes.AuthSaveToken]: (state, payload) => {
    state.token = payload;
    saveAccessToken(payload);
  },

  [AuthActionTypes.AuthSaveRefreshToken]: (state, payload) => {
    state.refreshToken = payload;
    localStorage.setItem('refresh_token', payload);
  },

  [AuthActionTypes.AuthDeleteToken]: (state, payload) => {
    state.token = null;
    localStorage.removeItem('access_token');
  },

  [AuthActionTypes.AuthDeleteRefreshToken]: (state, payload) => {
    state.refreshToken = null;
    localStorage.removeItem('refresh_token');
  },
};
