import store from './store';
import routes from './routes';

import JobListItem from "./components/job-list-item.vue";
import JobView from './components/job-view.vue';

export default {
  name: 'job',
  routes: routes,
  store: store,
  components: [
    JobListItem,
    JobView,
  ],
};
