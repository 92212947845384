
import state from "./state";
import getters from "./getters";

import genActions from "./actions";
import genMutations from "./mutations";
import genActionTypes from "./action-types";


export default function genModule(name, apiPath, actionTypes) {
  const module = {
    name,
    state,
    getters,
    actionTypes: { ...genActionTypes(name), ...actionTypes},
    actions: genActions(name, apiPath),
    mutations: genMutations(name),
    namespaced: true
  };

  return module;
}



