import store from './store';
import routes from './routes';

// import JobView from './components/job-view.vue';

export default {
  name: 'organization',
  routes: routes,
  store: store,
  components: [
  ],
};
