
// import { mapGetters } from 'vuex';

export const storeRecViewMixin = {
  data() {
    return {
      unwatchStoreRec: null,
      rec: null,
      recId: this.$route.params.recId,
      // vuexPath: '',
      vuexGetRecordAction: null,
    };
  },
  computed: {
    getRecById() {
      return this.$store.getters[this.vuexPath + '/getRecById'];
    },

    storeRec() {
      const data = this.getRecById(this.recId);

      if (! data && this.recId) {
        console.log('storeRec needs to refreshRec');
        this.refreshRec(true);
        return null;
      }

      return data;
    },

  },
  watch: {
    // 'storeRec': function(val) {
    //   this.rec = JSON.parse(JSON.stringify(val));
    // },
  },
  beforeMount() {
    this.unwatchStoreRec = this.$watch('storeRec', function(val) {
      if (val) {
        this.rec = JSON.parse(JSON.stringify(val));
      } else {
        this.rec = null;
      }
      });
  },
  mounted() {
    console.log('ounted called');
  },
  unmounted() {
    console.log('unmounted called');
  },
  deactivated() {
    console.log('deactivated called');
  },
  beforeUpdate() {
    console.log('beforeUpdate called');
  },
  beforeDestroy() {
    console.log('beforeDestroy called');
  },
  destroyed() {
    console.log('destroyed called');
  },
  created() {
    // console.log('starting created');
    if (! this.storeRec) {
      this.refreshRec(true);
    } else {
      if (this.storeRec) {
        this.rec = JSON.parse(JSON.stringify(this.storeRec));
      }
    }
    // console.log('done created');
  },
  methods: {
    refreshRec(force) {
      if (force || ! this.rec) {
        this.$store.dispatch(this.vuexPath + '/' + this.vuexGetRecordAction, { recId: this.recId });
      }
    },
    recordRemoved() {
      // this.unwatchStoreRec();
      this.recId = null;
      this.$router.back();
    },
  },
};

export default storeRecViewMixin;
