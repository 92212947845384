import store from './store';
import routes from './routes';

export default {
  name: 'admin',
  routes: routes,
  store: store,
  components: [
  ],
};
