import {date} from 'quasar';

function dateFilter (val, format) {
    if (format === undefined) {
      format = 'MMM D, YYYY';
    }
    return date.formatDate(val, format);
  }

function currencyFilter (val) {
    if (! val) {
        return '';
    }
    return '$' + (val).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export default async ({ Vue }) => {
  Vue.filter('date', dateFilter);
  Vue.filter('currency', currencyFilter);
};
