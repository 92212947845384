// commit is dispatch

import { AdminUsersActionTypes } from "./action-types";
import { AuthActionTypes } from "src/modules/auth/store/action-types";
import ApiService from "src/services/api.service";
import { notifyError, notifySuccess } from 'src/services/utils';
import { isEqual } from 'lodash'

export default {

  [AdminUsersActionTypes.AdminUsersSetPassRequest]: async ( _ctx, payload) => {
    try {
      await ApiService.post('/api/admin/users/setpass/' + payload.user_id, payload);
      notifySuccess('Password updated');
      return true;
    } catch (error) {
      console.log("caught error in AdminUsersSetpassRequest", error.response.data);
      notifyError('UsersSetpassRequest', error);
      throw error;
    }
  },

  [AdminUsersActionTypes.AdminBecomeUserRequest]: async function({ commit }, payload ) {
    try {
      const apiData = await ApiService.get('/jwt/auth/become/' + payload.userId);
      console.log('apiData', apiData);
      commit(`auth/${AuthActionTypes.AuthRefreshSuccess}`, apiData.data, {root:true});
      // commit(AuthActionTypes.AuthRefreshSuccess, authData);
      // commit(AdminActionTypes.AdminBecomeUserSuccess, apiData.data.rec);
      return true;
    } catch (error) {
      console.log("caught error in become user", error.response);
      notifyError('Become User Request', error);
      throw error;
    }

  },

};
