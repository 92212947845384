import { isEqual } from 'lodash';

export default {
  hasRecId: state => recId => state.recs !== null ? Object.keys(state.recs).includes(recId) : false,
  getRecById: state => recId => state.recs !== null ? state.recs[recId] : null,
  getQry: state => state.qry,
  totalRecords: state => state.total_recs,

  getPageSet: state => (pageId) => {
    return state.pages !== null && state.pages['' + pageId] ? state.pages['' + pageId].map(rid => state.recs[rid]) : [];
  },

  hasPageSet: state => (pageId) => {
    return state.pages !== null && state.pages['' + pageId] ? true : false;
  },

  tagTotals: state => state.totals && state.totals.tags ? state.totals.tags : [],
  tagTotalsHash: state => {
    let rv = {};
    state.totals.tags.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  tagCount: (state, getters) => ( tag ) => {
    return getters.tagTotalsHash()[tag];
  },
  tagTotalsAlpha: (state, getters) => {
    return [ ...getters.tagTotals].sort( (a,b) => (''+a._id).localeCompare(b._id));
  },


  stateTotals: state => state.totals && state.totals.states ? state.totals.states : [],
  stateTotalsHash: state => {
    let rv = {};
    state.totals.states.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  stateCount: (state, getters) => ( stateCode ) => {
    return getters.stateTotalsHash()[stateCode];
  },
  stateTotalsAlpha: (state, getters) => {
    return [ ...getters.stateTotals].sort( (a,b) => ('' + a.name).localeCompare(b.name));
  },

  getRecIdsByUserId: state => userId => {
    if (state.recs === null) {
      return [];
    }

    return Object.keys(state.recs).filter(pid => {
      if (state.recs[pid].user &&
          state.recs[pid].user._id == userId
        ) {
          return true;
        }

        return false;
    });
  },

  getRecsByUserId: (state, getters) => (userId, pageId, pageSize) => {
    if (pageId === undefined) { pageId = 1; }
    if (pageSize === undefined) { pageSize = 25; }
    let start = (pageId - 1) * pageSize;
    if (! state.recs) {
      return [];
    }

    return getters.getRecIdsByUserId(userId).slice(start, start + pageSize).map(pid => state.recs[pid]);
  },


}