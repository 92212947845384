<template>
  <div id="q-app">
    <div v-if="valid_version">
      <router-view />
    </div>
    <div v-else class="fullscreen bg-grey-8">
      <q-toolbar class="bg-black">
        <q-toolbar-title>
          <router-link :to="{ name: isLoggedIn ? 'home' : 'index' }" class="text-white">
            Degree Jobs
          </router-link>
        </q-toolbar-title>

        <q-space></q-space>
      </q-toolbar>

      <div class="q-pa-lg">
        <div class="text-white q-my-md">
          <p>
            <q-icon name="warning" color="yellow" size="lg" />
            The version of the site in your browser ({{ package_version }}) is out of date. It should be at least version {{ live_version }}.
          </p>
          <p>
            Please reload the page to get the newest version of the site.
          </p>
        </div>

        <q-btn color="green" no-caps icon-right="refresh" @click="reload()">Reload Page</q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthActionTypes } from './modules/auth/store/action-types';
// TODO
// import { PostActionTypes } from './store/module-post/action-types';
import { SiteActionTypes } from './modules/site/store/action-types';
import jobModule from './modules/job/store';
import { mapState } from 'vuex';
import semver from 'semver';

export default {
  name: 'App',
  data() {
    return {
      package_version: process.env.VERSION,
    };
  },
  computed: {
    ...mapState('site', {
      live_version: 'version',
    }),
    valid_version() {
      return this.live_version ? semver.gte(this.package_version, this.live_version) : false;
    },
  },
  created() {
    // TODO
    this.$store.dispatch('job/' + jobModule.actionTypes.LoadRecordsPageRequest, { page_id: 1 });
    this.$store.dispatch('job/' + jobModule.actionTypes.LoadTotalsRequest);

    // this.$store.dispatch( 'org/' + OrgActionTypes.OrgsLoadTotalsRequest );
    this.$store.dispatch('site/' + SiteActionTypes.SiteArraysLoadRequest);

    // if we have an access token refresh account. TODO...
    this.$store.dispatch(`auth/${AuthActionTypes.AuthRefreshRequest}`).then(() => {
      // console.log('promise done go to url', this.$store.state.auth.startUrl );
      // console.log('auth refresh from App.vue promise done');
      // this.$router.push( this.$store.state.auth.startUrl );
    });
  },
  methods: {
    reload() {
      window.location.reload(true);
    },
  },
};
</script>

<style></style>
