import axios from "axios";
import { TokenService } from "./storage.service";
import { Store }  from "../store";
import { AuthActionTypes } from 'src/modules/auth/store/action-types';
import { SiteActionTypes } from 'src/modules/site/store/action-types';

export const ApiService = {
  _401RefreshInterceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    this.setHeader();
  },

  setHeader() {
    /* eslint-disable dot-notation */
    axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource, data) {
    return axios.get(resource, { params: data });
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /*
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   */
  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    if (this._401RefreshInterceptor) {
      console.log('NOT Remouting interceptor');
      return;
    }

    /* eslint-disable no-underscore-dangle */
    this._401RefreshInterceptor = axios.interceptors.response.use(
      response => {
        if (response.data && response.data.version) {
          Store.dispatch(`site/${SiteActionTypes.SiteArraysSetVersion}`, response.data.version);
        }
        return response
      },
      async error => {
        if (error.request.status === 401) {
          console.log('CONFIG ', error.config.url);
          if (error.config.url.endsWith("/jwt/auth") ||
              error.config.url.endsWith("/jwt/auth/refresh") ||
              error.config.url.endsWith("/jwt/auth/refresh_user")
            ) {
            // Refresh token has failed. Logout the user
            // Store.dispatch("auth/logout");
            throw error;
          } else {
            // Try to refresh the access token
            try {
              console.log('trying base refresh');
              if (await Store.dispatch(`auth/${AuthActionTypes.AuthRefreshTokenRequest}`)) {
                // Retry the original request
                return this.customRequest({
                  method: error.config.method,
                  url: error.config.url,
                  data: error.config.data,
                });
              } else {
                throw error;
              }
            } catch (e) {
              // Refresh has failed - reject the original request
              console.log('caught e interceptor', e);
              throw error;
            }
          }
        }

        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    /* eslint-disable no-underscore-dangle */
    axios.interceptors.response.eject(this._401RefreshInterceptor);
  },
};

export default ApiService;
