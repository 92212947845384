// commit is dispatch

import { AuthActionTypes } from './action-types';
// import router from "../../router";
import { AuthService, AuthenticationError } from 'src/services/auth.service';
// import api from '../../utils/api';

export default {
  [AuthActionTypes.AuthLoginRequest]: async ({ commit }, payload) => {
    console.log('in login req action', payload);
    commit(AuthActionTypes.AuthLoginRequest, payload);
    console.log('in login2 req action', payload);

    try {
      const authData = await AuthService.login(payload.email, payload.password);
      // console.log('router hist', router.history.current);
      console.log('in login3 req action', authData);
      commit(AuthActionTypes.AuthLoginSuccess, authData);

      // Redirect the user to the page he first tried to visit or to the home view
      // console.log('router hist', router.history.current);
      // router.push(router.history.current.params.nextUrl || "/");

      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        console.log('loginError', e, {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });

        // SAVE ERROR DATA
        // commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });

        throw e;
      }
      return false;
    }
  },

  [AuthActionTypes.AuthLogoutRequest]: async ({ commit }) => {
    console.log('in logout req action');
    commit(AuthActionTypes.AuthLogoutSuccess);
  },

  [AuthActionTypes.AuthLoginSuccess]: async ({ commit }, payload) => {
    console.log('in login success action');
    commit(AuthActionTypes.AuthLoginSuccess, payload);
  },

  [AuthActionTypes.AuthRefreshTokenSuccess]: async ({ commit }, payload) => {
    commit(AuthActionTypes.AuthRefreshTokenSuccess, payload);
  },

  [AuthActionTypes.AuthRefreshTokenRequest]: async ({ commit }) => {
    try {
      const authData = await AuthService.refreshToken();
      if (!authData) {
        return false;
      }
      commit(AuthActionTypes.AuthRefreshTokenSuccess, authData);
      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        console.log('refreshError', e, {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });

        return false;
        // throw e;
      }
      return false;
    }
  },

  [AuthActionTypes.AuthRefreshRequest]: function({ commit, state }) {
    try {
      // console.log("in refresh req action 1");
      // const authData = await AuthService.refreshUser();
      // return true;
      return AuthService.refreshUser()
        .then(authData => {
          commit(AuthActionTypes.AuthRefreshSuccess, authData);

          console.log('refresh success - state is ', state.startUrl);
          // if (state.startUrl) {
          //   console.log('going to URL', state.startUrl);
          //   router().push({ path: state.startUrl });
          // }
        })
        .catch(() => {
          return false;
        });
    } catch (e) {
      console.log('HIT CATCH!');
      if (e instanceof AuthenticationError) {
        console.log('refresh Error', e, {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });

        // commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });

        throw e;
      }
      return false;
    }
  },

  [AuthActionTypes.AuthRefreshSuccess]: ({ commit }, payload) => {
    console.log('in refresh success action', payload);
    commit(AuthActionTypes.AuthRefreshSuccess, payload);
  },

  [AuthActionTypes.AuthGetUserSuccess]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthGetUserSuccess, payload);
  },

  [AuthActionTypes.AuthSetStartUrl]: ({ commit }, payload) => {
    console.log('in set start url', payload);
    commit(AuthActionTypes.AuthSetStartUrl, payload);
  },

  [AuthActionTypes.AuthAddRole]: ({ commit }, payload) => {
    console.log('caught add role in auth', payload);
    commit(AuthActionTypes.AuthAddRole, payload);
  },

  [AuthActionTypes.AuthRemoveRole]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthRemoveRole, payload);
  },

  [AuthActionTypes.AuthSetField]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthSetField, payload);
  },

  [AuthActionTypes.AuthSaveToken]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthSaveToken, payload);
  },

  [AuthActionTypes.AuthSaveRefreshToken]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthSaveRefreshToken, payload);
  },

  [AuthActionTypes.AuthDeleteToken]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthDeleteToken, payload);
  },

  [AuthActionTypes.AuthSetLoggedOut]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthSetLoggedOut, payload);
  },

  [AuthActionTypes.AuthDeleteRefreshToken]: ({ commit }, payload) => {
    commit(AuthActionTypes.AuthDeleteRefreshToken, payload);
  },
};
