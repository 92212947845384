export default {
  isLoading: state => !!state.loading,
  isLoggedIn: state => !!state.loggedIn,
  hasRole: state => role => {
    if (!state.user) {
      return false;
    }
    if (Array.isArray(state.user.scopes) && state.user.scopes.includes(role)) {
      return true;
    }

    return Array.isArray(state.user.roles) && state.user.roles.includes(role);
  },
  hasOrgId: state => orgId =>
    state.user && orgId && Array.isArray(state.user.organizations) && state.user.organizations.filter(o => o._id == orgId).length > 0,
};
