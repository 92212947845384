import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import users from './module-admin-user';

import posts from './module-admin-post';
import pending_posts from './module-admin-pending-post';
import orgs from './module-admin-org';
import onboarding from './module-admin-onboarding';
import marketing from './module-admin-marketing';
import feedback from './module-admin-feedback';
import orders from './module-admin-order';
import transactions from './module-admin-transaction';
import coupons from './module-admin-coupon';
import js_errors from './module-admin-js-error';
import server_errors from './module-admin-server-error';

import crawler from './module-admin-crawler';


export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
  modules: {
    users,
    posts,
    pending_posts,
    onboarding,
    marketing,
    feedback,
    orgs,
    orders,
    transactions,
    coupons,
    js_errors,
    server_errors,
    crawler,
  },
};

// import genModule from "../../store_base";
// let module = genModule('admin', '/api/admin');

// export default {
//   namespaced: true,
//   getters: {
//     ...module.getters,
//     ...getters,
//   },

//   actions: {
//     ...module.actions,
//     ...actions,
//   },

//   mutations: {
//     ...module.mutations,
//     ...mutations,
//   },

//   state: {
//     ...module.state,
//     ...state,
//   },

//   actionTypes: module.actionTypes,

// };
