export default {

  skillTotals: state => state.totals && state.totals.skills ? state.totals.skills : [],
  skillTotalsHash: state => {
    let rv = {};
    state.totals.skills.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  skillCount: (state, getters) => ( skill ) => {
    return getters.skillTotalsHash()[skill];
  },
  skillTotalsAlpha: (state, getters) => {
    return [ ...getters.skillTotals].sort( (a,b) => (''+a._id).localeCompare(b._id));
  },

  orgTypeTotals: state => state.totals && state.totals.org_types ? state.totals.org_types : [],
  orgTypeTotalsHash: state => {
    let rv = {};
    state.totals.org_types.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  orgTypeCount: (state, getters) => ( orgType ) => {
    return getters.orgTypeTotalsHash()[orgType];
  },
  orgTypeTotalsAlpha: (state, getters) => {
    return [ ...getters.orgTypeTotals].sort( (a,b) => (''+a._id).localeCompare(b._id));
  },

  contractTypeTotals: state => state.totals && state.totals.contract_types ? state.totals.contract_types : [],
  contractTypeTotalsHash: state => {
    let rv = {};
    state.totals.contract_types.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  contractTypeCount: (state, getters) => ( contractType ) => {
    return getters.contractTypeTotalsHash()[contractType];
  },
  contractTypeTotalsAlpha: (state, getters) => {
    return [ ...getters.contractTypeTotals].sort( (a,b) => (''+a._id).localeCompare(b._id));
  },

  countryTotals: state => state.totals && state.totals.countries ? state.totals.countries : [],
  countryTotalsHash: state => {
    let rv = {};
    state.totals.countries.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  countryCount: (state, getters) => ( country ) => {
    return getters.countryTotalsHash()[country];
  },
  countryTotalsAlpha: (state, getters) => {
    return [ ...getters.countryTotals].sort( (a,b) => (''+a._id).localeCompare(b._id));
  },

  degreeTotals: state => state.totals && state.totals.degrees ? state.totals.degrees : [],
  degreeTotalsHash: state => {
    let rv = {};
    state.totals.degrees.map( rec => {
      rv[ rec._id ] = rec.count;
    });
    return rv;
  },
  degreeCount: (state, getters) => ( degree ) => {
    return getters.degreeTotalsHash()[degree];
  },
  degreeTotalsAlpha: (state, getters) => {
    return [ ...getters.degreeTotals].sort( (a,b) => (''+a._id).localeCompare(b._id));
  },

};
