// commit is dispatch

import { AdminOnboardingActionTypes } from "./action-types";
import ApiService from "src/services/api.service";
import { notifyError, notifySuccess } from "src/services/utils";

export default {
  [AdminOnboardingActionTypes.AdminOnboardingLoadOrgRecordsRequest]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingLoadOrgRecordsRequest, payload);

    try {
      const userData = await ApiService.get('/api/admin/onboarding/orgs');
      console.log("in admin onboarding orgs load data action", userData);
      commit(AdminOnboardingActionTypes.AdminOnboardingLoadOrgRecordsSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in load onboaarding org records", error.response);
      notifyError('Load Onboarding Org Records Request', error);
      throw error;
    }
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobRecordsRequest]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingLoadJobRecordsRequest, payload);

    try {
      const userData = await ApiService.get('/api/admin/onboarding/jobs', payload);
      console.log("in admin onboarding jobs load data action", userData);
      commit(AdminOnboardingActionTypes.AdminOnboardingLoadJobRecordsSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in load onboaarding job records", error.response);
      notifyError('Load Onboarding Job Records Request', error);
      throw error;
    }
  },

  [AdminOnboardingActionTypes.AdminOnboardingJobGetRecordRequest]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingJobGetRecordRequest, payload);
    try {
      const apiData = await ApiService.get('/api/admin/onboarding/jobs/' + payload.jobId);
      console.log('apiData', apiData);
      commit(AdminOnboardingActionTypes.AdminOnboardingJobGetRecordSuccess, apiData.data);
      return true;
    } catch (error) {
      console.log("caught error in get onboarding job record", error.response);
      notifyError('Get Onboarding Job Record Request', error);
      throw error;
    }
  },

  [AdminOnboardingActionTypes.AdminOnboardingJobRecordsImportRequest]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingJobRecordsImportRequest, payload);

    try {
      const userData = await ApiService.post('/api/admin/onboarding/jobs/import', payload);
      console.log("in admin onboarding jobs import action", userData);
      notifySuccess('Imported ' + userData.data.data.imported.length + ' jobs');
      commit(AdminOnboardingActionTypes.AdminOnboardingJobRecordsImportSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in import onboaarding job records", error.response);
      notifyError('Import Onboarding Job Records Request', error);
      throw error;
    }
  },

  [AdminOnboardingActionTypes.AdminOnboardingLoadJobseekerRecordsRequest]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingLoadJobseekerRecordsRequest, payload);

    try {
      const userData = await ApiService.get('/api/admin/onboarding/jobseekers');
      console.log("in admin onboarding jobseekers load data action", userData);
      commit(AdminOnboardingActionTypes.AdminOnboardingLoadJobseekerRecordsSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in load onboaarding jobseeker records", error.response);
      notifyError('Load Onboarding Jobseeker Records Request', error);
      throw error;
    }
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetJobseekerFilterQry]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingSetJobseekerFilterQry, payload);
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetJobFilterQry]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingSetJobFilterQry, payload);
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetOrgFilterQry]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingSetOrgFilterQry, payload);
  },

  [AdminOnboardingActionTypes.AdminOnboardingAddFilterSkipImported]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingAddFilterSkipImported, payload);
  },

  [AdminOnboardingActionTypes.AdminOnboardingAddFilterSkipReviewed]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingAddFilterSkipReviewed, payload);
  },

  [AdminOnboardingActionTypes.AdminOnboardingSetOrgRecordsReviewedRequest]: async ({ commit }, payload) => {

    try {
      const userData = await ApiService.post('/api/admin/onboarding/orgs/reviewed', payload);
      console.log("in admin onboarding orgs load data action", userData);
      commit(AdminOnboardingActionTypes.AdminOnboardingSetOrgRecordsReviewedSuccess, userData.data);
      return true;
    } catch (error) {
      console.log("caught error in load onboaarding org records", error.response);
      notifyError('Load Onboarding Org Records Request', error);
      throw error;
    }
  },


  [AdminOnboardingActionTypes.AdminOnboardingInviteOrgRecordsSuccess]: async ({ commit }, payload) => {
    commit(AdminOnboardingActionTypes.AdminOnboardingInviteOrgRecordsSuccess, payload);
  },


};
