<template>
<div class="user_info">
  <span v-if="user">
    <span v-if="showId">
      [ <router-link :to="{name: userRoute, params: {recId: user._id}}">{{ user._id }}</router-link> ]
    </span>

    <router-link :to="{name: userRoute, params: {recId: user._id}}">
    <span>
      {{user.first_name || 'NoFirst'}}
    </span>

    <span>
      {{user.last_name || 'NoLast'}}
    </span>
    </router-link>

    <span v-if="showEmail">
      &lt;<a :href="'mailto:' + user.email">{{user.email || 'NoEmail'}}</a>&gt;
    </span>

  </span>
  <span v-else>
    Null User
  </span>
</div>
</template>
<style scoped>
.user_info {
  display: inline;
}
</style>
<script>
import { date } from 'quasar';
import { mapGetters } from 'vuex';

export default {
  name: 'UserName',
  filters: {
    date: (val, format) => {
      if (format === undefined) {
        format = 'MMM D YY';
      }
      return date.formatDate(val, format);
    },
  },
  props: {
    'user': {
      type: Object,
      default: null,
    },
    'showId': {
      type: Boolean,
      default: false,
      required: false
    },
    'showEmail': {
      type: Boolean,
      default: false,
      required: false
    },

  },
  data() {
    return {
      // userRoute: this.hasRole('admin') ? 'adminUserView'  : 'userView',
      // userRoute: 'userView',
    }
  },
  computed: {
    ...mapGetters('auth', [
      'hasRole',
    ]),
    userRoute() { return this.hasRole('admin') ? 'adminUsersView'  : 'usersView'; },
  },
  methods: {
  },
}
</script>

